<script setup lang="ts">
import { ref } from 'vue';

interface Props {
  name: string;
  labelI18nPath: string;
  modelValue?: File | { imageUrl: string, fileName: string };
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: File | undefined): void}>();
const imageValue = ref(props.modelValue instanceof File ? props.modelValue : undefined);

function getPreviewUrl() {
  return props.modelValue instanceof File ? undefined : props.modelValue?.imageUrl;
}

function getPreviewName() {
  return props.modelValue instanceof File ? undefined : props.modelValue?.fileName;
}

const previewUrl = ref(getPreviewUrl());
const previewName = ref(getPreviewName());

function resetPreview() {
  previewUrl.value = undefined;
  previewName.value = undefined;
}

function handleChange(value: File | undefined) {
  resetPreview();
  emit('update:modelValue', value);
}

</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <h4 class="text-base font-semibold text-gray-900">
      {{ $t(labelI18nPath) }}
    </h4>
    <base-image-input
      :model-value="imageValue"
      :name="name"
      :preview-url="previewUrl"
      :preview-name="previewName"
      @update:model-value="handleChange"
    />
  </div>
</template>
