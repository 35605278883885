<script setup lang="ts">
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import StarterKit from '@tiptap/starter-kit';
import { useEditor, EditorContent } from '@tiptap/vue-3';
import { useField } from 'vee-validate';

import useFieldError from '@/composables/useFieldError';

import BaseRichInputToolbar from './base-rich-input-toolbar.vue';

const labelWeightClass = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  normal: 'font-normal',
};

interface Props {
  modelValue?: string;
  labelI18nPath?: string;
  name: string;
  /** The font weight of the label. */
  weight?: keyof typeof labelWeightClass;
  titleLevel?: number;
  colors?: string[];
  options? : string[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  labelI18nPath: undefined,
  weight: 'normal',
  titleLevel: 1,
  colors: () => [],
  options: () => [],
});

const {
  value: inputValue,
  errorMessage,
  meta,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});
const { showError } = useFieldError({ errorMessage, meta });

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();

function updateInputValue(newValue: string) {
  inputValue.value = newValue;
  emit('update:modelValue', newValue);
}

const editor = useEditor({
  content: inputValue.value,
  extensions: [
    StarterKit,
    TextStyle,
    Color,
  ],
  onUpdate: ({ editor: updatedEditor }) => { updateInputValue(updatedEditor.getHTML()); },
});

</script>
<template>
  <div
    class="flex flex-col space-y-1"
    :class="$attrs.class"
  >
    <label
      v-if="labelI18nPath"
      :for="name"
      class="text-gray-700"
      :class="labelWeightClass[weight]"
    >
      {{ $t(labelI18nPath) }}
    </label>
    <base-rich-input-toolbar
      :editor="editor"
      :title-level="titleLevel"
      :colors="colors"
      :options="options"
    />
    <editor-content
      v-model="inputValue"
      :name="name"
      :data-testid="name"
      :editor="editor"
    />
    <p
      v-show="showError"
      class="text-left text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<style lang="scss">

.ProseMirror:focus {
  @apply outline-primary-700;
}

.ProseMirror {
  @apply p-2 border rounded border-gray-300 h-28 text-gray-700 overflow-y-auto;

  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  ul {
    padding: 0 1rem;
    @apply list-disc;
  }
  ol {
    padding: 0 1rem;
    @apply list-decimal;
  }
  blockquote {
    @apply pl-4 border-l-4 border-gray-300;
  }
}

</style>
