<script setup lang="ts">
import { PhPalette } from '@phosphor-icons/vue';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseMokContentModal from '@/components/base-mok-content-modal.vue';
import BaseMokRadioButtonGroup from '@/components/base-mok-radio-button-group.vue';
import type { SectionBaseTypeName } from '@/types/section-base-type-name';

export type Props = {
  isOpen: boolean;
  additionableBaseTypes: SectionBaseTypeName[];
}

const props = defineProps<Props>();
const { t } = useI18n();

const newSectionOptions = computed(() => (
  props.additionableBaseTypes.map((baseType) => ({
    name: baseType,
    label: t(`sections.${baseType}.name`),
    description: t(`sections.${baseType}.shortDescription`),
  }))
));

const newSectionBaseType = ref<SectionBaseTypeName>();
const emits = defineEmits<{(e: 'close'): void, (e: 'confirm'): void}>();

function addNewSection() {
  emits('close');
}

</script>

<template>
  <base-mok-content-modal
    :is-open="isOpen"
    :icon="PhPalette"
    :title="$t('sections.addModal.title')"
    :description="$t('sections.addModal.description')"
    :cancel-label="$t('actions.cancel')"
    :confirm-label="$t('actions.add')"
    @close="emits('close')"
    @confirm="addNewSection"
  >
    <base-mok-radio-button-group
      v-model="newSectionBaseType"
      :options="newSectionOptions"
    />
  </base-mok-content-modal>
</template>
