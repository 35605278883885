<script setup lang="ts">
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { ref, watch } from 'vue';

type Option = {
  name: string;
  label: string;
  description: string;
  disabled?: boolean;
};

export type Props = {
  modelValue?: string;
  options: Option[];
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
});

const emit = defineEmits<{(e: 'update:modelValue', value: string | undefined): void}>();

const selectedOptionName = ref(props.modelValue || props.options[0].name);

if (!props.modelValue) emit('update:modelValue', selectedOptionName.value);

watch(selectedOptionName, () => {
  emit('update:modelValue', selectedOptionName.value);
});

</script>
<template>
  <RadioGroup
    v-model="selectedOptionName"
    class="flex flex-col gap-y-2"
  >
    <RadioGroupOption
      v-for="option in options"
      :key="option.name"
      v-slot="{ checked }"
      :value="option.name"
      class="flex grow"
      :disabled="option.disabled"
    >
      <div
        :data-testid="option.name"
        class="flex grow items-center gap-x-4 rounded-lg border px-4 py-3"
        :class="{
          'border-primary-300 bg-primary-50': checked && !option.disabled,
          'border-gray-300': !checked,
          'bg-gray-50': option.disabled
        }"
      >
        <div
          class="flex aspect-square h-4 w-4 min-w-min items-center justify-center rounded-full border"
          :class="{
            'border-primary-700': checked && !option.disabled,
            'border-gray-400': !checked && !option.disabled,
            'border-gray-200 bg-gray-200': option.disabled }"
        >
          <div
            v-if="checked || option.disabled"
            class="h-2 w-2 rounded-full"
            :class="{'bg-primary-700': checked && !option.disabled, 'bg-gray-400': option.disabled}"
            name="circle"
          />
        </div>
        <div class="flex flex-col gap-y-1 text-start">
          <label
            class="text-sm font-medium"
            :class="{
              'text-primary-700': checked && !option.disabled,
              'text-gray-700': !checked && !option.disabled,
              'text-gray-400': option.disabled,
            }"
          >
            {{ option.label }}
          </label>
          <p
            class="text-xs"
            :class="{
              'text-primary-600': checked && !option.disabled,
              'text-gray-600': !checked && !option.disabled,
              'text-gray-400': option.disabled,
            }"
          >
            {{ option.description }}
          </p>
        </div>
      </div>
    </RadioGroupOption>
  </RadioGroup>
</template>
