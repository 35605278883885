<script setup lang="ts">
import { computed, inject, ref } from 'vue';

import useCouponsQuery from '@/composables/useCouponsQuery';
import type { Coupon } from '@/types/coupon';
import type { CuponstarCategory } from '@/types/cuponstar-category';
import type { WebApp } from '@/types/web-app';
import searchDebounce from '@/utils/search-debounce';

import WebAppFunctionalitiesCouponsActions from './web-app-functionalities-coupons-actions.vue';
import WebAppFunctionalitiesCouponsAddFiltersModal from './web-app-functionalities-coupons-add-filters-modal.vue';

interface Emits {
  (e: 'addCouponId', value: number): void;
  (e: 'undoAddCouponId', value: number): void;
}

interface Props {
  couponIdsToBeAdded: Set<number>;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const isFiltersModalOpen = ref(false);

const AMOUNT_TO_LOAD_NEXT = 10;
const couponAmountToShow = ref(AMOUNT_TO_LOAD_NEXT);

const webApp = inject<WebApp>('webApp');
const availableCoupons = inject<Coupon[]>('availableCoupons');
const cuponstarCategories = inject<CuponstarCategory[]>('cuponstarCategories');

const webAppId = computed(() => webApp?.id || 0);

const {
  coupons,
  filters,
  refetch,
} = useCouponsQuery(
  webAppId.value,
  availableCoupons,
);

const availableCouponsToShow = computed(() => (coupons.value?.slice(0, couponAmountToShow.value)));

function loadMoreCoupons(): void {
  couponAmountToShow.value += AMOUNT_TO_LOAD_NEXT;
}

const headers = [
  'name',
  'category',
  'discount',
  'actions',
];

function categoriesForCoupon(coupon: Coupon): string | undefined {
  return cuponstarCategories
    ?.filter(
      (category) => coupon.cuponstarCategories.some(
        (couponstarCategory) => couponstarCategory.id === category.id,
      ),
    )
    ?.map((category) => category.name)
    ?.join(', ');
}

const showLoadMoreButton = computed(
  () => couponAmountToShow.value < (coupons.value?.length || 0),
);

const debounceUpdateSearch = searchDebounce((value: string) => {
  filters.nameCont = value;
  refetch.value();
});

function updateFilters() {
  refetch.value();
  isFiltersModalOpen.value = false;
}

</script>
<template>
  <div class="flex w-full gap-6">
    <base-search
      class="grow"
      :model-value="filters.nameCont"
      :placeholder="$t('functionalities.coupons.searchByName')"
      name="search"
      @update:model-value="(value: string) => debounceUpdateSearch(value)"
    />
    <base-button
      theme="secondary"
      icon-file-name="filter"
      label-i18n-path="functionalities.coupons.filterButton"
      @click="isFiltersModalOpen = true"
    />
  </div>
  <div class="hidden w-full rounded-lg border border-gray-300 bg-white p-3 md:block">
    <table class="w-full table-fixed text-center text-gray-600">
      <thead>
        <tr class="my-auto h-17">
          <th
            v-for="header in headers"
            :key="header"
            class="font-medium"
          >
            {{ $t(`functionalities.coupons.${header}`) }}
          </th>
        </tr>
      </thead>
      <tbody class="font-normal">
        <tr
          v-for="coupon in availableCouponsToShow"
          :key="coupon.id"
          class="my-auto h-16 border-t border-gray-100 font-normal"
        >
          <td>
            {{ coupon.name }}
          </td>
          <td>
            {{ categoriesForCoupon(coupon) }}
          </td>
          <td>
            {{ coupon.discount }}
          </td>
          <td>
            <div
              class="flex items-center justify-center gap-2"
            >
              <web-app-functionalities-coupons-actions
                mode="add"
                :will-be-added="couponIdsToBeAdded.has(coupon.id)"
                @add="emit('addCouponId', coupon.id)"
                @undo-add="emit('undoAddCouponId', coupon.id)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    v-for="coupon in availableCouponsToShow"
    :key="coupon.id"
    class="flex w-full flex-col gap-4 rounded-lg border border-gray-300 bg-white p-5 text-xs md:hidden"
  >
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.name') }}
      </p>
      <p>
        {{ coupon.name }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.category') }}
      </p>
      <p>
        {{ categoriesForCoupon(coupon) }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.discount') }}
      </p>
      <p>
        {{ coupon.discount }}
      </p>
    </div>
    <div class="flex flex-col gap-2">
      <p class="font-medium">
        {{ $t('functionalities.coupons.actions') }}
      </p>
      <div class="flex items-center justify-start gap-2">
        <web-app-functionalities-coupons-actions
          mode="add"
          :will-be-added="couponIdsToBeAdded.has(coupon.id)"
          @add="emit('addCouponId', coupon.id)"
          @undo-add="emit('undoAddCouponId', coupon.id)"
        />
      </div>
    </div>
  </div>
  <div class="flex justify-center">
    <base-button
      v-if="showLoadMoreButton"
      label-i18n-path="functionalities.coupons.loadMore"
      size="sm"
      theme="secondary"
      @click="loadMoreCoupons"
    />
  </div>
  <web-app-functionalities-coupons-add-filters-modal
    :is-open="isFiltersModalOpen"
    @close="isFiltersModalOpen = false"
    @update-filters="updateFilters"
  />
</template>
