<script setup lang="ts">
import { camelize } from 'humps';
import { reactive, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import * as yup from 'yup';

import webAppSalesServiceApi from '@/api/webAppSalesService';
import type { Category } from '@/types/category';
import type { SalesServiceForm } from '@/types/sales-service-form';
import type { Service } from '@/types/service';

const { t } = useI18n();

interface Props {
  salesService?: Service;
  webAppId: number;
  category?: Category;
  open: boolean;
  hasLoyalty: boolean;
}

interface Emits {
  (event: 'close'): void;
  (event: 'save') : void;
  (event: 'delete') : void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const mode = props.salesService ? 'edit' : 'new';
const editMode = mode === 'edit' && props.salesService;
const createMode = mode === 'new' && props.category;

const textEditorOptions = ['bold', 'italic', 'bulletList', 'undo', 'redo'];

function getInitialServiceLoyaltyType() {
  const pointCost = props.salesService?.pointCost || 0;

  if (pointCost > 0) {
    return 'redeemable';
  }

  return 'included';
}

const serviceLoyaltyType = ref<'redeemable' | 'included'>(getInitialServiceLoyaltyType());

const defaultSalesServiceData: SalesServiceForm = {
  name: '',
  description: '',
  kind: 'redirect',
  link: undefined,
  categoryId: props.category?.id,
  image: undefined,
  pointCost: 0,
  redemptionKind: 'single_use',
  rootOnly: !props.category?.parentId,
};

function getSalesServiceData(salesService: Service) {
  const { name, description,
    kind, link, pointCost,
    redemptionKind, rootOnly,
  }: SalesServiceForm = salesService;

  return {
    name, description,
    kind, link, pointCost,
    redemptionKind, rootOnly,
  };
}

function getInitialSalesServiceData() {
  if (props.salesService) {
    return getSalesServiceData(props.salesService);
  }

  return defaultSalesServiceData;
}

const initialSalesServiceData: SalesServiceForm = getInitialSalesServiceData();

const salesServiceData = reactive<SalesServiceForm>(initialSalesServiceData);

const isRedeemable = computed(() => serviceLoyaltyType.value === 'redeemable');
const isIncluded = computed(() => serviceLoyaltyType.value === 'included');

const isRedirectKindService = computed(() => salesServiceData.kind === 'redirect');

const salesServiceSchema = yup.object().shape({
  name: yup.string().required().label(t('salesServices.fields.name')),
  description: yup.string().required().label(t('salesServices.fields.description')),
  link: yup.string().when('kind', {
    is: 'redirect',
    then: schema => schema.required().url(),
  })
    .label(t('salesServices.fields.link')),
  pointCost: yup.number().when(
    'serviceLoyaltyType',
    {
      is: 'redeemable',
      then: schema => schema.moreThan(0),
      otherwise: schema => schema.min(0),
    },
  )
    .label(t('salesServices.fields.pointCost')),
  redemptionKind: yup.string().when(
    'serviceLoyaltyType',
    {
      is: 'redeemable',
      then: schema => schema.required(),
      otherwise: schema => schema,
    },
  )
    .label(t('salesServices.fields.redemptionKind')),
});

function checkPointCost(values: SalesServiceForm) {
  if (isIncluded.value) {
    values.pointCost = 0;
  }

  return values;
}

function checkRedemptionKind(values: SalesServiceForm) {
  if (!isRedeemable.value) {
    values.redemptionKind = undefined;
  }

  return values;
}

function saveSalesServiceRequest(values: SalesServiceForm) {
  let updatedValues = checkPointCost(values);
  updatedValues = checkRedemptionKind(updatedValues);

  if (editMode) {
    return webAppSalesServiceApi.update(props.salesService.id, updatedValues);
  }

  if (createMode) {
    const { rootOnly } = salesServiceData;
    updatedValues = { ...updatedValues, rootOnly };

    return webAppSalesServiceApi.create(props.webAppId, props.category.id, updatedValues);
  }

  return Promise.reject();
}

const {
  mutate: saveSalesServiceMutation,
  isError, isLoading,
} = useMutation(
  saveSalesServiceRequest,
  { onSuccess: () => emit('save') },
);

const {
  mutate: deleteSalesServiceMutation,
} = useMutation(
  (salesServiceId: number) => webAppSalesServiceApi.delete(salesServiceId),
  { onSuccess: () => emit('delete') },
);

function saveSalesService(values: SalesServiceForm) {
  saveSalesServiceMutation(values);
}

function deleteSalesService(salesServiceId: number) {
  deleteSalesServiceMutation(salesServiceId);
}

</script>
<template>
  <base-modal
    :is-open="props.open"
    @close="$emit('close')"
  >
    <template #content>
      <h3 class="text-xl font-semibold">
        {{ $t(`salesServices.texts.title.${mode}`) }}
      </h3>
      <base-error
        v-if="isError"
        :message="$t('salesServices.errors.save')"
      />
      <h4 class="mt-3">
        <i18n-t
          keypath="salesServices.texts.mainExplanation"
        >
          <template
            #webApp
          >
            <span class="inline font-bold"> {{ $t('salesServices.texts.webApp') }} </span>
          </template>
        </i18n-t>
      </h4>
      <v-form
        validate-on-mount
        :validation-schema="salesServiceSchema"
        @submit="saveSalesService"
      >
        <div
          class="mt-8 space-y-4"
        >
          <div>
            <span class="mb-2 block text-sm font-normal text-gray-700">
              {{ $t('salesServices.fields.kind.label') }}
            </span>
            <div class="mb-6 flex flex-row space-x-4">
              <base-radio-input
                v-model="salesServiceData.kind"
                value="redirect"
                name="kind"
                :label="$t('salesServices.fields.kind.options.redirect')"
              />
              <base-radio-input
                v-model="salesServiceData.kind"
                value="immediate"
                name="kind"
                :label="$t('salesServices.fields.kind.options.immediate')"
              />
              <base-radio-input
                v-model="salesServiceData.kind"
                value="scheduled"
                name="kind"
                :label="$t('salesServices.fields.kind.options.scheduled')"
              />
            </div>
          </div>
          <base-input
            v-model="salesServiceData.name"
            label-i18n-path="salesServices.fields.name"
            :placeholder="$t('salesServices.fields.name')"
            name="name"
          />
          <base-rich-input
            v-model="salesServiceData.description"
            label-i18n-path="salesServices.fields.description"
            name="description"
            weight="bold"
            :options="textEditorOptions"
          />
          <base-image-input
            v-model="salesServiceData.image"
            :label="$t('salesServices.fields.image')"
            name="image"
          />
          <template v-if="hasLoyalty">
            <div>
              <span class="mb-2 block text-sm font-normal text-gray-700">
                {{ $t('salesServices.fields.loyalty.label') }}
              </span>
              <div class="mb-6 flex flex-row space-x-4">
                <base-radio-input
                  v-model="serviceLoyaltyType"
                  value="included"
                  name="serviceLoyaltyType"
                  :label="$t('salesServices.fields.serviceLoyaltyTypes.included.label')"
                />
                <base-radio-input
                  v-model="serviceLoyaltyType"
                  value="redeemable"
                  name="serviceLoyaltyType"
                  :label="$t('salesServices.fields.serviceLoyaltyTypes.redeemable.label')"
                />
              </div>
              <div class="rounded bg-gray-50 px-4 py-2 text-xs">
                {{ $t(`salesServices.fields.serviceLoyaltyTypes.${serviceLoyaltyType}.explanation`) }}
              </div>
            </div>
            <div v-if="isRedeemable">
              <span class="mb-2 block text-sm font-normal text-gray-700">
                {{ $t('salesServices.fields.redemptionKind') }}
              </span>
              <div class="mb-6 flex flex-row space-x-4">
                <base-radio-input
                  v-model="salesServiceData.redemptionKind"
                  value="single_use"
                  name="redemptionKind"
                  :label="$t('salesServices.fields.redemptionKinds.singleUse.label')"
                />
                <base-radio-input
                  v-model="salesServiceData.redemptionKind"
                  value="unlimited_access"
                  name="redemptionKind"
                  :label="$t('salesServices.fields.redemptionKinds.unlimitedAccess.label')"
                />
              </div>
              <div class="rounded bg-gray-50 px-4 py-2 text-xs">
                {{
                  $t(`salesServices.fields.redemptionKinds.${camelize(salesServiceData.redemptionKind)}.explanation`)
                }}
              </div>
            </div>
            <base-input
              v-if="isRedeemable"
              v-model="salesServiceData.pointCost"
              type="number"
              name="pointCost"
              label-i18n-path="salesServices.fields.pointCost"
              theme="numeric-input"
            />
          </template>
          <base-input
            v-if="isRedirectKindService"
            v-model="salesServiceData.link"
            label-i18n-path="salesServices.fields.link"
            :placeholder="$t('salesServices.fields.link')"
            name="link"
          />
          <base-button
            v-if="editMode && props.salesService"
            type="button"
            theme="primary-link"
            label-i18n-path="webAppCountryServices.actions.delete"
            @click="deleteSalesService(props.salesService.id)"
          />
        </div>
        <div class="mt-8 flex justify-end space-x-6">
          <base-button
            type="button"
            theme="secondary"
            label-i18n-path="actions.cancel"
            @click="$emit('close')"
          />
          <base-button
            type="submit"
            theme="primary"
            label-i18n-path="actions.save"
            :loading="isLoading"
          />
        </div>
      </v-form>
    </template>
  </base-modal>
</template>
