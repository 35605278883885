<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useMutation, useQuery } from 'vue-query';

import webAppServiceApi from '@/api/webAppService';
import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import type { WebApp } from '@/types/web-app';
import { WebAppService } from '@/types/web-app-service';
import { WebAppServiceOption } from '@/types/web-app-service-option';
import type { WebAppTabs } from '@/types/web-app-tabs';

import BaseBadge from './base-badge.vue';
import ThePageHeader from './the-page-header.vue';
import WebAppServicesFormModal from './web-app-services-form-modal.vue';
import WebAppServicesServiceCard from './web-app-services-service-card.vue';

interface Props {
  webApp: WebApp;
  backPath: string;
  tabs: WebAppTabs;
  webAppServices: WebAppService[];
  webAppServiceOptions: WebAppServiceOption[];
}
const props = defineProps<Props>();

const {
  data: webAppServicesData,
  refetch: refetchWebAppServices,
} = useQuery(['web-app-services', props.webApp.id],
  () => (webAppServiceApi.index(props.webApp.id)),
  { initialData: props.webAppServices, enabled: false, retry: false },
);
const updatedWebAppServices = computed(() => (webAppServicesData.value || []));

const noServices = computed(() => updatedWebAppServices.value.length === 0);

const VISIBILITY_TIMEOUT = 3000;
const isServiceSaveSuccess = ref(false);
const serviceSuccessMode = ref<'edit' | 'create'>();
const serviceSuccessMessage = computed(() => `webAppServices.index.serviceSuccessMessage.${serviceSuccessMode.value}`);
const { isVisible: isSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isServiceSaveSuccess, timeout: VISIBILITY_TIMEOUT },
);
watch(isSuccessMessageVisible, (isVisible) => {
  isServiceSaveSuccess.value = isVisible;
});

const visibleServiceForm = ref(false);
const selectedWebAppService = ref<WebAppService>();
function openServiceModal() {
  visibleServiceForm.value = true;
}
function editService(webAppService: WebAppService) {
  selectedWebAppService.value = webAppService;
  openServiceModal();
}
function closeServiceModal() {
  visibleServiceForm.value = false;
  selectedWebAppService.value = undefined;
}
function serviceSaveSuccess() {
  isServiceSaveSuccess.value = true;
  serviceSuccessMode.value = selectedWebAppService.value ? 'edit' : 'create';
  closeServiceModal();
  refetchWebAppServices.value();
}

const deleteWebAppServiceModalOpen = ref(false);
function openDeleteServiceModal(webAppService: WebAppService) {
  selectedWebAppService.value = webAppService;
  deleteWebAppServiceModalOpen.value = true;
}
function cancelDeleteWebAppService() {
  deleteWebAppServiceModalOpen.value = false;
  selectedWebAppService.value = undefined;
}
const {
  mutate: deleteWebAppServiceMutate,
} = useMutation(
  (webAppService: WebAppService) => webAppServiceApi.delete(webAppService.id),
  { onSuccess: () => { refetchWebAppServices.value(); } },
);
async function deleteWebAppService() {
  await deleteWebAppServiceMutate(selectedWebAppService.value);
  deleteWebAppServiceModalOpen.value = false;
  selectedWebAppService.value = undefined;
}

const notSelectedWebAppServiceOptions = computed(() => (
  props.webAppServiceOptions.filter((webAppServiceOption: WebAppServiceOption) =>
    !updatedWebAppServices.value.some((webAppService: WebAppService) =>
      webAppService.ownerId === webAppServiceOption.id &&
        webAppService.ownerType === webAppServiceOption.className,
    ),
  )
));
</script>

<template>
  <the-web-app-layout
    :web-app="webApp"
    selected-tab="webAppServices"
    :tabs="tabs"
    :back-path="backPath"
  >
    <the-page-header
      title-i18n-path="webAppServices.index.title"
      description-i18n-path="webAppServices.index.subtitle"
      emoji="🔑"
    >
      <template #notice>
        <base-badge
          v-if="isSuccessMessageVisible"
          :label="$t(serviceSuccessMessage)"
          color="success"
          theme="dark"
          data-testid="service-success-badge"
        />
      </template>
    </the-page-header>
    <div
      v-if="noServices"
      class="mx-auto flex w-52 flex-col items-center justify-center text-center"
    >
      <div class="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-primary-100">
        <base-svg
          class="h-8 w-8 stroke-primary-700"
          name="clipboard"
        />
      </div>
      <p class="mb-4 font-semibold text-black">
        {{ $t('webAppServices.index.noServices') }}
      </p>
      <p class="mb-6 text-sm text-gray-500">
        {{ $t('webAppServices.index.createFirstService') }}
      </p>
      <base-button
        data-testid="add-first-service-button"
        theme="primary"
        icon-file-name="plus-circle"
        icon-position="start"
        label-i18n-path="webAppServices.index.addServiceButton"
        size="sm"
        @click="openServiceModal"
      />
    </div>
    <template v-else>
      <div
        class="mb-6"
      >
        <span class="mr-8 font-semibold text-gray-900">
          {{ $t('webAppServices.index.addServiceTitle') }}
        </span>
        <base-button
          data-testid="add-service-button"
          theme="secondary"
          icon-file-name="add"
          icon-position="start"
          label-i18n-path="webAppServices.index.addServiceButton"
          size="xs"
          @click="openServiceModal"
        />
      </div>
      <span class="mb-8 text-sm text-gray-900">
        {{ $t('webAppServices.index.addServiceSubtitle') }}
      </span>
      <div
        data-testid="web-app-services-list"
        class="grid grid-cols-4 gap-4 rounded-lg border border-gray-100 px-4 py-8 xl:grid-cols-5"
      >
        <web-app-services-service-card
          v-for="(webAppService, index) in updatedWebAppServices"
          :key="`${webAppService.id}-${index}`"
          :web-app-service="webAppService"
          @edit="editService"
          @delete="openDeleteServiceModal"
        />
      </div>
    </template>
  </the-web-app-layout>
  <web-app-services-form-modal
    v-if="visibleServiceForm"
    :web-app-service="selectedWebAppService"
    :open="visibleServiceForm"
    :has-loyalty="webApp.hasLoyalty"
    :web-app-id="webApp.id"
    :is-sales-web-app="webApp.appType === 'sales'"
    :web-app-service-options="notSelectedWebAppServiceOptions"
    @save="serviceSaveSuccess"
    @close="closeServiceModal"
  />
  <base-mok-alert-modal
    :is-open="deleteWebAppServiceModalOpen"
    theme="warning"
    icon="alert-triangle"
    :title="$t('webAppServices.index.deleteServiceModal.title')"
    :description="$t('webAppServices.index.deleteServiceModal.description')"
    :confirm-label="$t('actions.delete')"
    :cancel-label="$t('actions.cancel')"
    @confirm="deleteWebAppService"
    @close="cancelDeleteWebAppService"
  />
</template>
