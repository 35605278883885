<script setup lang="ts">
import isEqual from 'lodash/isEqual';
import { useForm } from 'vee-validate';
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { object, string, boolean } from 'yup';

import webAppApi from '@/api/webApp';
import type { EnvConfig } from '@/types/env-config';
import type { WebApp } from '@/types/web-app';

import WebAppFeature from './web-app-feature.vue';

export type Props = {
  webApp: WebApp;
  envConfig: EnvConfig;
  canEnableCoupons: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: 'update-success'): void;
  (e: 'update-error'): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n({});

interface WebAppFunctionalitiesForm extends WebApp {
  envConfigAttributes: Partial<EnvConfig>
}

const webAppSchema = object({
  isConnectedToSso: boolean(),
  envConfigAttributes: object().when('isConnectedToSso', {
    is: true,
    then: () => object({
      ssoClientId: string().required().label(t('functionalities.fields.sso.ssoClientId')),
      ssoSecretKey: string().required().label(t('functionalities.fields.sso.ssoSecretKey')),
    }),
  }),
  hasLoyalty: boolean(),
  hasCoupons: boolean(),
  isTermsAndConditionsActive: boolean(),
  isPrivacyPolicyActive: boolean(),
  isNavigationBetweenWebAppsActive: boolean(),
});

const initialWebAppData = reactive<Partial<WebAppFunctionalitiesForm>>({
  isConnectedToSso: props.webApp.isConnectedToSso,
  envConfigAttributes: props.envConfig,
  hasLoyalty: props.webApp.hasLoyalty,
  hasCoupons: props.webApp.hasCoupons,
  isTermsAndConditionsActive: props.webApp.isTermsAndConditionsActive,
  isPrivacyPolicyActive: props.webApp.isPrivacyPolicyActive,
  isNavigationBetweenWebAppsActive: props.webApp.isNavigationBetweenWebAppsActive,
});

const {
  resetForm, values: webAppData, setValues: setWebAppData,
  validate, errors,
} = useForm<WebAppFunctionalitiesForm>(
  {
    initialValues: { ...initialWebAppData },
    validationSchema: webAppSchema,
    validateOnMount: true,
  },
);

const disabledActions = computed(() => (isEqual(webAppData, initialWebAppData)));
const ssoConnectionDisabled = computed(() => (!props.webApp.canConnectToSso));
const loyaltyConnectionDisabled = computed(() => (!webAppData.isConnectedToSso));

const {
  mutate: updateWebAppMutation,
  isLoading: isUpdateLoading,
} = useMutation(
  () => webAppApi.update(props.webApp.id, webAppData, false),
  {
    onSuccess: (response) => {
      emit('update-success');
      setWebAppData(response.data.webApp);
      Object.assign(initialWebAppData, response.data.webApp);
    },
    onError: () => emit('update-error'),
  },
);

async function updateWebApp() {
  await validate();
  if (Object.keys(errors.value).length !== 0) return;
  updateWebAppMutation();
}

watch(() => webAppData.isConnectedToSso, (newIsConnectedToSsso) => {
  if (!newIsConnectedToSsso) {
    setWebAppData({ hasLoyalty: false });
  }
});

</script>
<template>
  <form
    id="web-app-edit-form"
    @submit.prevent="updateWebApp"
  >
    <div class="flex w-full flex-col gap-2 gap-x-8 rounded-lg border border-gray-300 bg-white p-3 text-xs">
      <web-app-feature
        :title="$t('functionalities.fields.sso.title')"
        :description="$t('functionalities.fields.sso.description')"
        :disabled="ssoConnectionDisabled"
        :disabled-description="$t('functionalities.fields.sso.connectionDisabled')"
      >
        <template #toggle>
          <base-switch
            v-model="webAppData.isConnectedToSso"
            :disabled="!webApp.canConnectToSso"
            name="isConnectedToSso"
          />
        </template>
        <div class="grid w-full max-w-3xl grid-cols-2 items-stretch gap-x-8 px-3">
          <base-input
            v-model="webAppData.envConfigAttributes.ssoClientId"
            :placeholder="$t('functionalities.fields.sso.ssoClientId')"
            label-i18n-path="functionalities.fields.sso.ssoClientId"
            theme="floating-label-input"
            name="envConfigAttributes.ssoClientId"
            size="xs"
            :disabled="ssoConnectionDisabled"
          />
          <base-input
            v-model="webAppData.envConfigAttributes.ssoSecretKey"
            :placeholder="$t('functionalities.fields.sso.ssoSecretKey')"
            label-i18n-path="functionalities.fields.sso.ssoSecretKey"
            theme="floating-label-input"
            name="envConfigAttributes.ssoSecretKey"
            size="xs"
            :disabled="ssoConnectionDisabled"
          />
        </div>
      </web-app-feature>
      <web-app-feature
        :title="$t('functionalities.fields.iLoyalty.title')"
        :description="$t('functionalities.fields.iLoyalty.description')"
        :disabled="loyaltyConnectionDisabled"
        :disabled-description="$t('functionalities.fields.iLoyalty.connectionDisabled')"
      >
        <template #toggle>
          <base-switch
            v-model="webAppData.hasLoyalty"
            :disabled="!webAppData.isConnectedToSso"
            name="hasLoyalty"
          />
        </template>
      </web-app-feature>
      <web-app-feature
        data-testid="coupons-feature"
        :title="$t('functionalities.fields.coupons.title')"
        :description="$t('functionalities.fields.coupons.description')"
        :disabled="!canEnableCoupons"
        :disabled-description="$t('functionalities.fields.coupons.connectionDisabled')"
      >
        <template #toggle>
          <base-switch
            v-if="canEnableCoupons"
            v-model="webAppData.hasCoupons"
            data-testid="coupons-switch"
            name="hasCoupons"
          />
        </template>
      </web-app-feature>
      <web-app-feature
        :title="$t('functionalities.fields.isNavigationBetweenWebAppsActive.title')"
        :description="$t('functionalities.fields.isNavigationBetweenWebAppsActive.description')"
      >
        <template #toggle>
          <base-switch
            v-model="webAppData.isNavigationBetweenWebAppsActive"
            name="isNavigationBetweenWebAppsActive"
          />
        </template>
      </web-app-feature>
      <web-app-feature
        :title="$t('functionalities.fields.termsAndConditions.title')"
        :description="$t('functionalities.fields.termsAndConditions.description')"
        :disabled="!webApp.canEnableTermsAndConditions"
        :disabled-description="$t('functionalities.fields.termsAndConditions.connectionDisabled')"
      >
        <template #toggle>
          <base-switch
            v-if="webApp.canEnableTermsAndConditions"
            v-model="webAppData.isTermsAndConditionsActive"
            name="isTermsAndConditionsActive"
          />
        </template>
      </web-app-feature>
      <web-app-feature
        :title="$t('functionalities.fields.privacyPolicy.title')"
        :description="$t('functionalities.fields.privacyPolicy.description')"
        :disabled="!webApp.canEnablePrivacyPolicy"
        :disabled-description="$t('functionalities.fields.privacyPolicy.connectionDisabled')"
      >
        <template #toggle>
          <base-switch
            v-if="webApp.canEnablePrivacyPolicy"
            v-model="webAppData.isPrivacyPolicyActive"
            name="isPrivacyPolicyActive"
          />
        </template>
      </web-app-feature>
    </div>
    <div class="mt-4 flex grow justify-end gap-4 text-sm md:text-base">
      <base-button
        theme="secondary"
        :disabled="disabledActions"
        label-i18n-path="actions.cancel"
        @click="resetForm"
      />
      <base-button
        type="submit"
        theme="primary"
        :loading="isUpdateLoading"
        label-i18n-path="actions.saveChanges"
        :disabled="disabledActions"
      />
    </div>
  </form>
</template>
