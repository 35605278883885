<script setup lang="ts">
import { computed, useAttrs } from 'vue';

interface Props {
  icon: string;
  selected?: boolean;
  label: string;
}

withDefaults(defineProps<Props>(), {
  selected: false,
});

const attrs = useAttrs();
const currentTag = computed(() => (attrs.href ? 'a' : 'button'));

</script>
<template>
  <component
    :is="currentTag"
    class="flex items-center justify-center space-x-2 rounded-lg px-1 py-2 md:justify-start"
    :class="{
      'bg-primary-50': selected,
      'hover:bg-gray-100': !selected,
    }"
  >
    <div class="flex aspect-square h-10 items-center justify-center p-2">
      <base-svg
        :name="icon"
        class="h-6"
        :class="{
          'stroke-gray-500': !selected,
          'stroke-primary-700': selected,
        }"
      />
    </div>
    <span
      class="hidden text-sm md:flex"
      :class="{
        'text-gray-500': !selected,
        'text-primary-700': selected,
      }"
    >
      {{ $t(label) }}
    </span>
  </component>
</template>
