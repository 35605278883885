<script setup lang="ts">
import SessionForm from './session-form.vue';

interface Props {
  errors: string[];
  new?: boolean;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    errors: () => [],
    new: false,
  },
);

const action = props.new ? 'createPassword' : 'editPassword';

</script>

<template>
  <SessionForm
    :title="$t(`users.titles.${action}`)"
    :errors="errors"
    :action-text="`users.actions.${action}`"
  >
    <template #inputs>
      <base-input
        label-i18n-path="users.fields.password"
        name="user[password]"
        type="password"
      />
      <base-input
        label-i18n-path="users.fields.passwordConfirmation"
        name="user[password_confirmation]"
        type="password"
      />
    </template>
  </SessionForm>
  <slot />
</template>
