<script setup lang="ts">
import { camelize } from 'humps';
import { reactive, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import * as yup from 'yup';

import webAppCountryServiceApi from '@/api/webAppCountryService';
import type { WebAppCountryService } from '@/types/web-app-country-service';
import type { WebAppCountryServiceForm } from '@/types/web-app-country-service-form';

interface Props {
  webAppCountryService: WebAppCountryService;
  open: boolean;
  hasLoyalty: boolean;
}

interface Emits {
  (event: 'close'): void;
  (event: 'save') : void;
  (event: 'delete') : void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const isOpen = ref(props.open);

const { t } = useI18n({});

function getInitialWebappCountryServiceType() {
  if (props.webAppCountryService.pointCost !== 0) {
    return 'redeemable';
  } else if (Number(props.webAppCountryService.accumulationRate) === 0) {
    return 'included';
  }

  return 'rewardable';
}
const webappCountryServiceType = ref(getInitialWebappCountryServiceType());

function getInitialRedemptionKind() {
  if (props.webAppCountryService.redemptionKind) {
    return props.webAppCountryService.redemptionKind;
  } else if (['scheduled', 'immediate'].includes(props.webAppCountryService.kind)) {
    return 'single_use';
  }

  return 'unlimited_access';
}

const webAppCountryServiceData = reactive<WebAppCountryServiceForm>({
  customName: props.webAppCountryService.name,
  customDescription: props.webAppCountryService.description,
  customImage: undefined,
  accumulationRate: props.webAppCountryService.accumulationRate,
  pointCost: props.webAppCountryService.pointCost,
  customRedemptionKind: getInitialRedemptionKind(),
});

const isRedeemable = computed(() => webappCountryServiceType.value === 'redeemable');
const isIncluded = computed(() => webappCountryServiceType.value === 'included');
const isRewardable = computed(() => webappCountryServiceType.value === 'rewardable');

const webAppCountryServiceSchema = yup.object().shape({
  customName: yup.string().required().label(t('webAppCountryServices.fields.name')),
  customDescription: yup.string().required(t('webAppCountryServices.fields.description')),
  type: yup.string().label(t('webAppCountryServices.fields.type')),
  accumulationRate: yup.number().test(
    'decimal-validation',
    t('webAppCountryServices.fields.maxDecimals'),
    (value: number | undefined) => {
      const regex = /^\d+(\.\d{1,4})?$/;
      const stringValue = value?.toString() ?? '';

      return regex.test(stringValue) || !isRewardable.value || !props.hasLoyalty;
    },
  ).when(
    'type',
    {
      is: 'rewardable',
      then: (schema) => schema.moreThan(0),
      otherwise: (schema) => schema.min(0),
    },
  )
    .label(t('webAppCountryServices.fields.accumulationRate')),
  pointCost: yup.number().when(
    'type',
    {
      is: 'redeemable',
      then: (schema) => schema.moreThan(0),
      otherwise: (schema) => schema.min(0),
    },
  )
    .label(t('webAppCountryServices.fields.pointCost')),
  customRedemptionKind: yup.string().when(
    'type',
    {
      is: 'redeemable',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    },
  )
    .label(t('webAppCountryServices.fields.redemptionKind')),
});

function checkPointCostAndAccumulationRate(values: WebAppCountryServiceForm) {
  if (isIncluded.value) {
    values.pointCost = 0;
    values.accumulationRate = 0.0;
  } else if (isRedeemable.value) {
    values.accumulationRate = 0.0;
  } else if (isRewardable.value) {
    values.pointCost = 0;
  }

  return values;
}
function checkRedemptionKind(values: WebAppCountryServiceForm) {
  if (!isRedeemable.value) {
    values.customRedemptionKind = null;
  }

  return values;
}

function updateWebAppCountryServiceRequest(values: WebAppCountryServiceForm) {
  let updatedValues = checkPointCostAndAccumulationRate(values);
  updatedValues = checkRedemptionKind(updatedValues);

  return webAppCountryServiceApi.update(props.webAppCountryService.id, updatedValues);
}

const {
  mutate,
  isError, isLoading,
} = useMutation(
  updateWebAppCountryServiceRequest,
  { onSuccess: () => emit('save') },
);

function updateWebAppCountryService(values: WebAppCountryServiceForm) {
  mutate(values);
}

const {
  mutate: deleteWebAppCountryService,
} = useMutation(
  () => webAppCountryServiceApi.delete(props.webAppCountryService.id),
  { onSuccess: () => emit('delete') },
);

</script>
<template>
  <base-modal
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template #content="{ setInitialFocusRef }">
      <h3 class="text-xl font-semibold">
        {{ webAppCountryService.name }}
      </h3>
      <base-error
        v-if="isError"
        :message="$t('webAppCountryServices.errors.update')"
      />
      <h4 class="mt-3">
        <i18n-t
          keypath="webAppCountryServices.customization.mainExplanation"
        >
          <template
            #webApp
          >
            <span class="inline font-bold"> {{ $t('webAppCountryServices.customization.webApp') }} </span>
          </template>
        </i18n-t>
      </h4>
      <v-form
        validate-on-mount
        :validation-schema="webAppCountryServiceSchema"
        @submit="updateWebAppCountryService"
      >
        <div
          class="mt-8 space-y-4"
        >
          <base-input
            :ref="setInitialFocusRef"
            v-model="webAppCountryServiceData.customName"
            label-i18n-path="webAppCountryServices.fields.name"
            :placeholder="$t('webAppCountryServices.fields.name')"
            name="customName"
          />
          <base-image-input
            v-model="webAppCountryServiceData.customImage"
            :label="$t('webAppCountryServices.fields.image')"
            name="customImage"
          />
          <base-rich-input
            v-model="webAppCountryServiceData.customDescription"
            label-i18n-path="webAppCountryServices.fields.description"
            name="customDescription"
            weight="bold"
            :options="['bold', 'italic', 'bulletList', 'undo', 'redo']"
          />
          <template v-if="hasLoyalty">
            <div>
              <span class="mb-2 block text-sm font-normal text-gray-700">
                {{ $t('webAppCountryServices.fields.type') }}
              </span>
              <div class="mb-6 flex flex-row space-x-4">
                <base-radio-input
                  v-model="webappCountryServiceType"
                  value="included"
                  name="type"
                  :label="$t('webAppCountryServices.fields.types.included.label')"
                />
                <base-radio-input
                  v-model="webappCountryServiceType"
                  value="rewardable"
                  name="type"
                  :label="$t('webAppCountryServices.fields.types.rewardable.label')"
                />
                <base-radio-input
                  v-model="webappCountryServiceType"
                  value="redeemable"
                  name="type"
                  :label="$t('webAppCountryServices.fields.types.redeemable.label')"
                />
              </div>
              <div class="rounded bg-gray-50 px-4 py-2 text-xs">
                {{ $t(`webAppCountryServices.fields.types.${webappCountryServiceType}.explanation`) }}
              </div>
            </div>
            <div v-if="isRedeemable">
              <span class="mb-2 block text-sm font-normal text-gray-700">
                {{ $t('webAppCountryServices.fields.redemptionKind') }}
              </span>
              <div class="mb-6 flex flex-row space-x-4">
                <base-radio-input
                  v-model="webAppCountryServiceData.customRedemptionKind"
                  value="single_use"
                  name="customRedemptionKind"
                  :label="$t('webAppCountryServices.fields.redemptionKinds.singleUse.label')"
                />
                <base-radio-input
                  v-model="webAppCountryServiceData.customRedemptionKind"
                  value="unlimited_access"
                  name="customRedemptionKind"
                  :label="$t('webAppCountryServices.fields.redemptionKinds.unlimitedAccess.label')"
                />
              </div>
              <div class="rounded bg-gray-50 px-4 py-2 text-xs">
                <!-- eslint-disable-next-line max-len vue/max-len -->
                {{ $t(`webAppCountryServices.fields.redemptionKinds.${camelize(webAppCountryServiceData.customRedemptionKind)}.explanation`) }}
              </div>
            </div>
            <base-input
              v-if="isRewardable"
              v-model="webAppCountryServiceData.accumulationRate"
              type="number"
              name="accumulationRate"
              label-i18n-path="webAppCountryServices.fields.accumulationRate"
              theme="numeric-input"
            />
            <base-input
              v-else-if="isRedeemable"
              v-model="webAppCountryServiceData.pointCost"
              type="number"
              name="pointCost"
              label-i18n-path="webAppCountryServices.fields.pointCost"
              theme="numeric-input"
            />
          </template>
          <base-button
            type="button"
            theme="primary-link"
            label-i18n-path="webAppCountryServices.actions.delete"
            @click="deleteWebAppCountryService"
          />
        </div>
        <div class="mt-8 flex justify-end space-x-6">
          <base-button
            type="button"
            theme="secondary"
            label-i18n-path="actions.cancel"
            @click="$emit('close')"
          />
          <base-button
            type="submit"
            theme="primary"
            label-i18n-path="actions.save"
            :loading="isLoading"
          />
        </div>
      </v-form>
    </template>
  </base-modal>
</template>
