<script setup lang="ts">
import { camelize } from 'humps';
import { ref, computed, provide, type ComputedRef } from 'vue';
import { useMutation } from 'vue-query';

import sectionApi from '@/api/section';
import BaseBadge from '@/components/base-badge.vue';
import BaseButton from '@/components/base-button.vue';
import BaseMokAlertModal from '@/components/base-mok-alert-modal.vue';
import theWebAppLayout from '@/components/the-web-app-layout.vue';
import { useSectionDestroy } from '@/composables/useSectionDestroy';
import useSectionForm from '@/composables/useSectionForm';
import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import type { DesignConfig } from '@/types/design-config';
import type { Section } from '@/types/section';
import type { SectionBaseTypeName } from '@/types/section-base-type-name';
import type { SectionOptions } from '@/types/sections/section-options';
import type { WebApp } from '@/types/web-app';
import type { WebAppTabs } from '@/types/web-app-tabs';
import getSectionFieldComponentName from '@/utils/get-section-field-component-name';

import SectionFormAddModal from './section-form-add-modal.vue';
import SectionFormDescription from './section-form-description.vue';
import SectionFormPreview from './section-form-preview.vue';
import SectionFormStyleSelect from './section-form-style-select.vue';
import ThePageHeader from '../the-page-header.vue';

export type Props = {
  webApp: WebApp,
  designConfig?: DesignConfig;
  section: Section;
  options: SectionOptions;
  navbar: Section;
  viewSections: Section[];
  footer: Section;
  backPath: string;
  tabs: WebAppTabs;
  additionableBaseTypes: SectionBaseTypeName[];
}

const props = defineProps<Props>();
const updatedSection = ref(props.section);
const sectionBaseType: ComputedRef<SectionBaseTypeName> = computed(() => camelize(props.section.baseType));
provide('designConfig', props.designConfig);

const {
  selectedOptionFields,
  selectedOptionName: selectedType,
  values: sectionFieldsData,
  valuesToSave: sectionFieldsDataToSave,
  showActions,
} = useSectionForm(
  { section: updatedSection, options: props.options },
);

function getSectionFieldLabelI18nPath(sectionFieldName: string) {
  return `sections.${sectionBaseType.value}.sectionFields.${sectionFieldName}`;
}

function getSectionFieldDescriptionI18nPath(sectionFieldName: string) {
  return `sections.${sectionBaseType.value}.sectionFieldsDescriptions.${sectionFieldName}`;
}

const descriptions = computed(() => (
  ['first', 'second', 'third'].map((key) => ({
    emojiI18nPath: `sections.${sectionBaseType.value}.descriptions.emojis.${key}`,
    contentI18nPath: `sections.${sectionBaseType.value}.descriptions.content.${key}`,
  }))
));

const sectionData = computed(() => (
  {
    type: selectedType.value,
    baseType: sectionBaseType.value,
    position: updatedSection.value.position,
    sectionFields: sectionFieldsDataToSave.value }
));

const {
  mutate: updateSection,
  isLoading: isUpdateLoading,
  isError: isUpdateError,
  isSuccess: isUpdateSuccess,
} = useMutation(
  () => sectionApi.update({ sectionId: props.section.id, sectionData: sectionData.value }),
  {
    onSuccess: (response) => {
      updatedSection.value = response.section;
    },
  },
);

const VISIBILITY_TIMEOUT = 3000;

const { isVisible: isUpdateSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateSuccess, timeout: VISIBILITY_TIMEOUT },
);
const { isVisible: isUpdateErrorMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateError, timeout: VISIBILITY_TIMEOUT },
);

const {
  showDestroyModal,
  openDestroyModal,
  closeDestroyModal,
  destroySection,
  isLoading: isDestroyLoading,
  isError: isDestroyError,
} = useSectionDestroy(
  { sectionId: props.section.id, successRedirectPath: props.navbar.path },
);

const showAddSectionModal = ref(false);

function openAddSectionModal() {
  showAddSectionModal.value = true;
}

function closeAddSectionModal() {
  showAddSectionModal.value = false;
}

const { isVisible: isDestroyErrorMessageVisible } = useVisibilityTimeout(
  { condition: isDestroyError, timeout: VISIBILITY_TIMEOUT },
);

const isErrorMessageVisible = computed(() => (
  isUpdateErrorMessageVisible.value || isDestroyErrorMessageVisible.value
));

const canAddSection = computed(() => (
  props.additionableBaseTypes.length > 0
));

</script>

<template>
  <the-web-app-layout
    data-testid="the-web-app-layout"
    :web-app="webApp"
    selected-tab="sections"
    :tabs="tabs"
    :back-path="backPath"
  >
    <the-page-header
      title-i18n-path="sections.pageTitle"
      description-i18n-path="sections.pageDescription"
      emoji="⭐️"
    >
      <template #notice>
        <base-badge
          v-if="isErrorMessageVisible"
          :label="$t('common.saveError')"
          color="error"
          theme="dark"
        />
        <base-badge
          v-else-if="isUpdateSuccessMessageVisible"
          :label="$t('common.savedSuccessfully')"
          color="success"
          theme="dark"
        />
      </template>
    </the-page-header>
    <base-mok-alert-modal
      data-testid="destroy-section-modal"
      :is-open="showDestroyModal"
      :loading="isDestroyLoading"
      icon="trash"
      theme="warning"
      :title="$t('sections.destroyModal.title')"
      :description="$t('sections.destroyModal.description')"
      :cancel-label="$t('actions.cancel')"
      :confirm-label="$t('actions.delete')"
      @close="closeDestroyModal"
      @confirm="destroySection"
    />
    <section-form-add-modal
      :is-open="showAddSectionModal"
      :additionable-base-types="additionableBaseTypes"
      @close="closeAddSectionModal"
    />
    <div class="flex gap-10 md:grid md:grid-cols-8 md:gap-8">
      <section-form-preview
        v-model:position="updatedSection.position"
        data-testid="sections-tabs"
        class="md:col-span-4"
        :navbar="navbar"
        :view-sections="viewSections"
        :footer="footer"
        :selected-section-id="section.id"
        :selected-section-type="selectedType"
        :is-destroyable="section.destroyable"
        :can-add-section="canAddSection"
        @destroy="openDestroyModal"
        @add="openAddSectionModal"
      />
      <div class="flex-col space-y-6 md:col-span-4">
        <section-form-description
          :section-name="sectionBaseType"
          :descriptions="descriptions"
        />
        <form class="flex flex-col space-y-3">
          <section-form-style-select
            v-model="selectedType"
            data-testid="section-options"
            :section-base-type="sectionBaseType"
            :selected-option-name="selectedType"
            :options="options"
          />
          <component
            :is="getSectionFieldComponentName(sectionField)"
            v-for="(sectionField, sectionFieldName) in selectedOptionFields"
            :key="`${selectedType}-${sectionFieldName}`"
            v-model="sectionFieldsData[sectionFieldName]"
            :label-i18n-path="getSectionFieldLabelI18nPath(sectionFieldName.toString())"
            :description-i18n-path="getSectionFieldDescriptionI18nPath(sectionFieldName.toString())"
            :name="sectionFieldName"
          />
          <div
            v-if="showActions"
            class="flex justify-end gap-4"
          >
            <base-button
              theme="secondary"
              label-i18n-path="actions.cancel"
            />
            <base-button
              type="button"
              theme="primary"
              :loading="isUpdateLoading"
              label-i18n-path="actions.saveChanges"
              @click="updateSection"
            />
          </div>
        </form>
      </div>
    </div>
  </the-web-app-layout>
</template>
