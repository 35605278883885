<script setup lang="ts">
import partition from 'lodash/partition';
import { computed, provide, ref } from 'vue';
import { useMutation } from 'vue-query';

import webAppApi from '@/api/webApp';
import useWebAppCountryServicesQuery from '@/composables/useWebAppCountryServicesQuery';
import type { Category } from '@/types/category';
import type { WebAppCountryServicesForm } from '@/types/web-app-country-services-form';

import CountryServicesSectionAddFamiliesOption from './country-services-section-add-families-option.vue';
import CountryServicesSectionAddFamiliesServices from './country-services-section-add-families-services.vue';

interface Props {
  webAppId: number;
  families: Category[];
  activeFamilies: Category[];
  categories: Category[];
}

interface Emits {
  (e: 'cancel'): void;
  (e: 'saved'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

function categoriesForFamily(family: Category) {
  return props.categories.filter((category) =>
    (category.parentId === family.id));
}

function familyAlreadyAdded(family: Category) {
  return props.activeFamilies.some((activeFamily) => (activeFamily.id === family.id));
}

const orderedFamilies = ref(partition(
  props.families,
  familyAlreadyAdded,
));

const selectedFamilyIds = ref<number[]>([]);
const selectedCountryServiceIds = ref<number[]>([]);

provide('selectedFamilyIds', selectedFamilyIds);
provide('selectedCountryServiceIds', selectedCountryServiceIds);

const selectedFamilies = computed(() => (
  props.families.filter((family) => (selectedFamilyIds.value.includes(family.id)))
));

const {
  refetch: refetchWebAppCountryServices,
} = useWebAppCountryServicesQuery(props.webAppId);

const {
  mutate,
} = useMutation(
  (values: WebAppCountryServicesForm) => webAppApi.updateCountryServices(values),
  { onSuccess: () => {
    refetchWebAppCountryServices.value();
    emit('saved');
  } },
);

function saveWebAppServices() {
  const webAppCountryServicesForm: WebAppCountryServicesForm = {
    webApp: { id: props.webAppId },
    newCountryServicesIds: selectedCountryServiceIds.value,
    deletedWebAppCountryServices: [],
  };

  mutate(webAppCountryServicesForm);
}

const currentStep = ref<'families' | 'services'>('families');
</script>
<template>
  <div
    v-if="currentStep === 'families'"
  >
    <div class="flex grow justify-end gap-4 border-b border-gray-200 pb-4 text-sm md:text-base">
      <base-button
        theme="secondary"
        label-i18n-path="actions.cancel"
        @click="() => $emit('cancel')"
      />
      <base-button
        theme="primary"
        :disabled="selectedFamilyIds.length === 0"
        label-i18n-path="actions.next"
        @click="currentStep = 'services'"
      />
    </div>
    <div
      class="mx-5 my-4 flex flex-col gap-4"
    >
      <div class="flex flex-col gap-1">
        <h2 class="font-bold text-slate-600">
          {{ $t('webAppCategories.addFamilies.title') }}
        </h2>
        <h4 class="text-xs">
          {{ $t('webAppCategories.addFamilies.subtitle') }}
        </h4>
      </div>
      <country-services-section-add-families-option
        v-for="family in orderedFamilies[0]"
        :key="family.id"
        :family="family"
        :categories="categoriesForFamily(family)"
        :already-added="true"
      />
      <country-services-section-add-families-option
        v-for="family in orderedFamilies[1]"
        :key="family.id"
        :family="family"
        :categories="categoriesForFamily(family)"
        :already-added="false"
      />
    </div>
  </div>
  <div
    v-else-if="currentStep === 'services'"
  >
    <div class="flex grow justify-end gap-4 border-b border-gray-200 pb-4 text-sm md:text-base">
      <base-button
        theme="secondary"
        label-i18n-path="actions.return"
        @click="currentStep = 'families'"
      />
      <base-button
        theme="primary"
        label-i18n-path="actions.save"
        @click="saveWebAppServices"
      />
    </div>
    <div
      class="mx-5 my-4 flex flex-col gap-4"
    >
      <div class="flex flex-col gap-1">
        <h2 class="font-bold text-slate-600">
          {{ $t('webAppCategories.addServices.title') }}
        </h2>
        <h4 class="text-xs">
          {{ $t('webAppCategories.addServices.subtitle') }}
        </h4>
      </div>
      <country-services-section-add-families-services
        v-for="family in selectedFamilies"
        :key="family.id"
        :family="family"
        :categories="categoriesForFamily(family)"
      />
    </div>
  </div>
</template>
