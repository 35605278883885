
import { computed, ComputedRef } from 'vue';

import { WebAppService } from '@/types/web-app-service';
import { WebAppServiceOption } from '@/types/web-app-service-option';

export default function useInitialLoyaltyValues(
  baseService: ComputedRef<WebAppService | WebAppServiceOption | undefined>,
) {
  const initialLoyaltyType = computed(() => {
    if (baseService.value?.pointCost !== 0) {
      return 'redeemable';
    } else if ('accumulationRate' in baseService.value && Number(baseService.value.accumulationRate) !== 0) {
      return 'rewardable';
    }

    return 'included';
  });
  const initialRedemptionKind = computed(() => {
    if (baseService.value?.redemptionKind) {
      return baseService.value.redemptionKind;
    } else if (
      !baseService.value ||
      initialLoyaltyType.value !== 'redeemable' ||
      (baseService.value.kind && ['scheduled', 'immediate'].includes(baseService.value.kind))
    ) {
      return 'single_use';
    }

    return 'unlimited_access';
  });

  return { initialLoyaltyType, initialRedemptionKind };
}
