<script setup lang="ts">
import { RadioGroup } from '@headlessui/vue';
import { ref, watch } from 'vue';

import type { SectionOptions } from '@/types/sections/section-options';
import type { SectionTypeName } from '@/types/sections/section-type-name';

import SectionFormStyleSelectOption from './section-form-style-select-option.vue';

export type Props = {
  options: SectionOptions;
  modelValue: SectionTypeName;
  sectionBaseType: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: SectionTypeName): void}>();

const selectedOptionName = ref(props.modelValue);

watch(selectedOptionName, () => {
  emit('update:modelValue', selectedOptionName.value);
});

</script>
<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <h3 class="text-base font-semibold text-gray-900">
      {{ $t('sections.sectionType') }}
    </h3>
    <RadioGroup
      v-model="selectedOptionName"
      class="grid grid-cols-2 gap-4"
    >
      <section-form-style-select-option
        v-for="(_option, optionName) in options"
        :key="optionName"
        :data-testid="`${optionName}Option`"
        :section-base-type="sectionBaseType"
        :name="optionName"
      />
    </RadioGroup>
  </div>
</template>
