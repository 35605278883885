<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { useMutation } from 'vue-query';

import webAppCouponApi from '@/api/webAppCoupon';
import useWebAppCouponsQuery from '@/composables/useWebAppCouponsQuery';
import type { WebApp } from '@/types/web-app';
import type { WebAppCoupon } from '@/types/web-app-coupon';

import WebAppFunctionalitiesCouponsAdd from './web-app-functionalities-coupons-add.vue';
import WebAppFunctionalitiesCouponsList from './web-app-functionalities-coupons-list.vue';

const webApp = inject<WebApp>('webApp');
const webAppCoupons = inject<WebAppCoupon[]>('webAppCoupons', []);

const webAppId = computed(() => webApp?.id || 0);

const isAddingCoupons = ref(false);
const couponIdsToAdd = ref<Set<number>>(new Set());

const {
  refetch,
} = useWebAppCouponsQuery(
  webAppId.value,
  webAppCoupons,
);

const { mutate } = useMutation(
  (couponIds : number[]) => webAppCouponApi.bulkCreate(
    webAppId.value,
    couponIds,
  ),
  {
    onSuccess: () => {
      refetch.value();
      couponIdsToAdd.value = new Set();
      isAddingCoupons.value = false;
    },
  },
);

function saveAddedCoupons() {
  mutate(Array.from(couponIdsToAdd.value));
}
</script>
<template>
  <div class="flex flex-col gap-4 pb-3 text-xs text-gray-600">
    <div class="order-last self-end md:order-first">
      <base-button
        v-if="!isAddingCoupons"
        size="sm"
        theme="primary"
        icon-file-name="plus-circle"
        label-i18n-path="functionalities.coupons.addCoupons"
        @click="isAddingCoupons = true"
      />
      <div
        v-else
        class="flex gap-2"
      >
        <base-button
          theme="secondary"
          label-i18n-path="actions.cancel"
          @click="isAddingCoupons = false"
        />
        <base-button
          theme="primary"
          label-i18n-path="actions.save"
          @click="saveAddedCoupons"
        />
      </div>
    </div>
    <web-app-functionalities-coupons-list
      v-if="!isAddingCoupons"
    />
    <web-app-functionalities-coupons-add
      v-else
      :coupon-ids-to-be-added="couponIdsToAdd"
      @add-coupon-id="(couponId: number) => couponIdsToAdd.add(couponId)"
      @undo-add-coupon-id="(couponId: number) => couponIdsToAdd.delete(couponId)"
    />
  </div>
</template>
