import { camelize } from 'humps';
import { computed, reactive, watch, ref, type ComputedRef, type Ref } from 'vue';

import type { Section } from '@/types/section';
import type { SectionFieldsSchema } from '@/types/sections/section-fields-schema';
import type { SectionOptions } from '@/types/sections/section-options';
import type { SectionTypeName } from '@/types/sections/section-type-name';

function useValues({ section, selectedOptionFields }: {
  section: Ref<Section>;
  selectedOptionFields: ComputedRef<SectionFieldsSchema | undefined>;
}) {
  const sectionFields = computed(() => section.value.sectionFields);
  const sectionFieldsValues = computed(() => Object.fromEntries(
    sectionFields.value.map(sectionField => [camelize(sectionField.name), sectionField.value]),
  ));
  const values = reactive(
    Object.fromEntries(
      Object.keys(selectedOptionFields.value || {}).map(key => [key, sectionFieldsValues.value[key]]),
    ),
  );

  watch(sectionFields, () => {
    const newValues = Object.fromEntries(
      Object.keys(selectedOptionFields.value || {}).map(key => [key, sectionFieldsValues.value[key]]),
    );
    Object.assign(values, newValues);
  });

  return { values };
}

function useValuesToSave({ values, section }: { values: Record<string, unknown>, section: Ref<Section>}) {
  const valuesToSave = computed(() =>
    Object.fromEntries(
      Object.entries(values).filter(([key, value]) =>
        section.value.sectionFields.find(
          (sectionField: { name: string; }) => camelize(sectionField.name) === key)?.value !== value,
      ),
    ),
  );

  return { valuesToSave };
}

function useShowActions({ options, selectedOptionFields }: {
  options: SectionOptions;
  selectedOptionFields: ComputedRef<SectionFieldsSchema | undefined>;
}) {
  const hasMultipleOptions = computed(() => (Object.keys(options).length > 1));
  const hasSelectedOptionFields = computed(() => (
    selectedOptionFields.value && Object.keys(selectedOptionFields.value).length > 0
  ));
  const showActions = computed(() => (hasMultipleOptions.value || hasSelectedOptionFields.value));

  return { showActions };
}

export default function useSectionForm(props: {
  options: SectionOptions;
  section: Ref<Section>;
}) {
  const selectedOptionName: Ref<SectionTypeName> = ref(camelize(props.section.value.type));
  const selectedOptionFields = computed(() => props.options[selectedOptionName.value]);

  const { values } = useValues({ section: props.section, selectedOptionFields });
  const { valuesToSave } = useValuesToSave({ values, section: props.section });
  const { showActions } = useShowActions({ options: props.options, selectedOptionFields });

  return { selectedOptionName, selectedOptionFields, values, valuesToSave, showActions };
}
