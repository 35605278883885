<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import type { SectionBaseTypeName } from '@/types/section-base-type-name';
import type { SectionDescription } from '@/types/section-description';

const { t } = useI18n();

export type Props = {
  descriptions: SectionDescription[];
  sectionName: SectionBaseTypeName;
}

const props = defineProps<Props>();

const title = computed(() => (t(`sections.${props.sectionName}.name`)));
</script>
<template>
  <h2 class="mb-4 font-semibold ">
    <span class="text-primary-700">
      {{ title }}
    </span>
  </h2>
  <div class="space-y-4">
    <div
      v-for="(description, index) in descriptions"
      :key="index"
      class="flex items-center md:w-5/6"
    >
      <div class="flex h-8 w-8 shrink-0 items-center justify-center rounded border border-gray-200">
        <span>{{ $t(description.emojiI18nPath) }}</span>
      </div>
      <div class="ml-4">
        <p class="text-xs text-gray-900">
          {{ $t(description.contentI18nPath) }}
        </p>
      </div>
    </div>
  </div>
</template>
