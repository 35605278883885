<script setup lang="ts">

interface Props {
  name: string;
  labelI18nPath: string;
  descriptionI18nPath: string;
  modelValue?: boolean;
}

defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: boolean | undefined): void}>();

function handleChange(value: boolean | undefined) {
  emit('update:modelValue', value);
}

</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <div class="flex w-full justify-between">
      <h4 class="text-base font-semibold text-gray-900">
        {{ $t(labelI18nPath) }}
      </h4>
      <base-switch
        :model-value="modelValue"
        :name="name"
        @update:model-value="handleChange"
      />
    </div>
    <p class="text-xs font-normal text-gray-500">
      {{ $t(descriptionI18nPath) }}
    </p>
  </div>
</template>
