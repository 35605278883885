<script setup lang="ts">
import { computed, useAttrs } from 'vue';

export type Props = {
  icon: string;
  theme?: 'primary' | 'secondary';
}

const props = withDefaults(defineProps<Props>(), {
  theme: 'primary',
});

const attrs = useAttrs();
const currentTag = computed(() => (attrs.href ? 'a' : 'button'));
const buttonStyles = {
  primary: 'bg-primary-700 hover:bg-primary-600 disabled:bg-primary-300',
  secondary: 'bg-purple-400 hover:bg-purple-300 disabled:bg-purple-300',
};

const buttonStyle = computed(() => buttonStyles[props.theme]);

</script>

<template>
  <component
    :is="currentTag"
    class="absolute z-10 flex h-min justify-center self-center rounded-r-md p-2"
    v-bind="attrs"
    :class="buttonStyle"
  >
    <base-svg
      :name="icon"
      class="h-4 w-4 stroke-white"
    />
  </component>
</template>
