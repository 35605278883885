<script setup lang="ts">
import { computed } from 'vue';

import { WebAppService } from '@/types/web-app-service';

export interface Props {
  kind?: WebAppService['kind'];
}

const props = defineProps<Props>();

const kindTagColorClasses = {
  'redirect': 'bg-violet-500 text-white',
  'authenticated_redirect': 'bg-pink-400 text-white',
  'immediate': 'bg-rose-500 text-white',
  'scheduled': 'bg-blue-700 text-white',
};
defineExpose({ kindTagColorClasses });

const kindTagColorClass = computed(() => props.kind && kindTagColorClasses[props.kind]);
</script>

<template>
  <span
    v-if="kind"
    class="rounded-full"
    :class="kindTagColorClass"
  >
    {{ $t(`webAppServices.service.kind.${kind}`) }}
  </span>
</template>
