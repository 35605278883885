<script setup lang="ts">
import { reactive, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { object, string, number } from 'yup';

import webAppApi from '@/api/webApp';
import type { Country } from '@/types/country';
import type { SalesHerokuApp } from '@/types/sales-heroku-app';
import type { Sponsor } from '@/types/sponsor';
import type { WebAppForm } from '@/types/web-app-form';
import regexPatterns from '@/values/regex-patterns';

const props = defineProps<{
  sponsors: Sponsor[];
  backPath: string;
  countries: Country[];
  appTypes:('productive' | 'sales')[];
  availableSalesHerokuApps?: SalesHerokuApp[];
  salesSponsorId: number;
}>();

const { t } = useI18n({});

const appTypesOptions = props.appTypes.map((appType) => ({ name: appType, id: appType }));
const webApp = reactive<WebAppForm>({
  sponsorId: undefined,
  contractId: undefined,
  countryId: undefined,
  appType: undefined,
  name: undefined,
  infrastructureType: 'heroku_public',
  herokuAppName: undefined,
  designConfigAttributes: {
    primaryColor: undefined,
    secondaryColor: undefined,
  },
  envConfigAttributes: {
    mokUser: undefined,
    mokPassword: undefined,
    adminUserEmail: undefined,
    adminUserPassword: undefined,
    sidekiqAdminPassword: undefined,
  },
});
const isSalesApp = computed(() => webApp.appType === 'sales');
const showNotAvailableSalesAppsWarning = computed(() => isSalesApp.value && !props.availableSalesHerokuApps?.length);
const showAvailableSalesApps = computed(() => isSalesApp.value && props.availableSalesHerokuApps?.length);
const isHeroku = computed(() => ['heroku_public', 'heroku_private'].includes(webApp.infrastructureType || ''));

const infrastructureTypes: { name: string; id: 'heroku_public' | 'heroku_private' | 'on_premises' }[] = [
  { name: t('webApp.fields.infrastructureTypes.herokuPublic'), id: 'heroku_public' },
  { name: t('webApp.fields.infrastructureTypes.herokuPrivate'), id: 'heroku_private' },
  { name: t('webApp.fields.infrastructureTypes.onPremises'), id: 'on_premises' },
];

const webAppSchema = object({
  sponsorId: string().required().label(t('webApp.fields.sponsorId')),
  contractId: number().typeError(t('validates.number', { field: t('webApp.fields.contractId') }))
    .label(t('webApp.fields.contractId')),
  countryId: string().required().label(t('webApp.fields.countryId')),
  name: string().required().label(t('webApp.fields.name')),
  infrastructureType: object()
    .when('appType', {
      is: 'productive',
      then: () => string().required().label(t('webApp.fields.infrastructureType')),
      otherwise: (schema) => schema.shape({}),
    }),
  herokuAppName: string()
    .when('infrastructureType', {
      is: () => isHeroku.value,
      then: () => string().matches(
        regexPatterns.kebabCaseWithNumbers,
        t('webApp.errors.herokuAppNameFormat'),
      ).required(),
    }).label(t('webApp.fields.herokuAppName')),
  designConfig: object({
    primaryColor: string().required().label(t('designConfig.fields.primaryColor')),
    secondaryColor: string().required().label(t('designConfig.fields.secondaryColor')),
  }),
  appType: string()
    .required()
    .oneOf(['productive', 'sales'])
    .label(t('webApp.fields.appType')),
  envConfig: object()
    .when('appType', {
      is: 'productive',
      then: (schema) => schema.shape({
        mokUser: string().required().label(t('envConfig.fields.mokUser')),
        mokPassword: string().required().label(t('envConfig.fields.mokPassword')),
        adminUserEmail: string().required().label(t('envConfig.fields.adminUserEmail')),
        adminUserPassword: string().required().label(t('envConfig.fields.adminUserPassword')),
        sidekiqAdminPassword: string().required().label(t('envConfig.fields.sidekiqAdminPassword')),
      }),
      otherwise: (schema) => schema.shape({}),
    }),
});

const {
  mutate: createWebAppMutation,
  isLoading,
  isError,
} = useMutation(
  () => webAppApi.create(webApp),
  { onSuccess: () => { window.location.href = props.backPath; } },
);

watch(isSalesApp, () => {
  webApp.herokuAppName = undefined;
  webApp.envConfigAttributes.mokUser = undefined;
  webApp.envConfigAttributes.mokPassword = undefined;
  webApp.envConfigAttributes.adminUserEmail = undefined;
  webApp.envConfigAttributes.adminUserPassword = undefined;
  webApp.envConfigAttributes.sidekiqAdminPassword = undefined;
  webApp.infrastructureType = 'heroku_public';

  if (!webApp.sponsorId && isSalesApp.value) {
    webApp.sponsorId = props.salesSponsorId;
  }
});

</script>
<template>
  <the-navbar />
  <v-form
    id="web-app-form"
    class="grid grid-cols-1 space-y-5 p-8 md:px-48"
    :validation-schema="webAppSchema"
    validate-on-mount
    @submit="createWebAppMutation"
  >
    <div class="flex flex-col items-start">
      <base-button
        :href="backPath"
        icon-file-name="back"
        label-i18n-path="actions.back"
      />
      <h1 class="text-3xl">
        {{ $t('webApp.titles.new') }}
      </h1>
    </div>
    <hr class="border-gray-300">
    <base-error
      v-if="isError"
      :message="$t('webApp.errors.create')"
    />
    <div class="flex items-center space-x-3">
      <base-svg
        name="menu-list"
        class="stroke-primary-700 "
      />
      <h1 class="text-xl">
        {{ $t("webApp.title") }}
      </h1>
    </div>
    <div class="space-y-10">
      <div class="grid grid-cols-1 gap-x-8 gap-y-3 md:grid-cols-2">
        <base-select
          v-model="webApp.sponsorId"
          :options="sponsors"
          label="webApp.fields.sponsorId"
          name="sponsorId"
          model-key="id"
          :warning="$t('webApp.fields.sponsorWarning')"
          allow-empty
        />
        <base-select
          v-model="webApp.countryId"
          :options="countries"
          label="webApp.fields.countryId"
          name="countryId"
          model-key="id"
          :warning="$t('webApp.fields.countryWarning')"
        />
        <base-select
          v-model="webApp.appType"
          :options="appTypesOptions"
          label="webApp.fields.appType"
          name="appType"
          model-key="id"
        />
        <base-input
          v-model="webApp.name"
          label-i18n-path="webApp.fields.name"
          :placeholder="$t('webApp.fields.name')"
          name="name"
        />
        <p
          v-if="showNotAvailableSalesAppsWarning"
          class="text-left text-xs text-primary-700"
        >
          &#9888; {{ $t('webApp.fields.notAvailableSalesApps') }}
        </p>
        <base-select
          v-else-if="showAvailableSalesApps"
          v-model="webApp.herokuAppName"
          :options="availableSalesHerokuApps"
          label="webApp.fields.herokuAppName"
          name="herokuAppName"
          model-key="name"
        />
        <base-input
          v-else-if="isHeroku"
          v-model="webApp.herokuAppName"
          label-i18n-path="webApp.fields.herokuAppName"
          :placeholder="$t('webApp.fields.herokuAppName')"
          name="herokuAppName"
        />
        <base-input
          v-if="!isSalesApp"
          v-model.number="webApp.contractId"
          label-i18n-path="webApp.fields.contractId"
          :placeholder="$t('webApp.fields.contractId')"
          name="contractId"
        />
      </div>
      <div v-if="!isSalesApp">
        <div class="mb-6 flex items-center space-x-3">
          <base-svg
            name="doc"
            class="h-6 stroke-primary-700"
          />
          <h1 class="text-xl">
            {{ $t('webApp.titles.infrastructureSection') }}
          </h1>
        </div>
        <base-button-group
          v-model="webApp.infrastructureType"
          :options="infrastructureTypes"
          name="infrastructureType"
        />
      </div>
      <div>
        <div class="mb-6 flex items-center space-x-3">
          <base-svg
            name="art"
            class="stroke-primary-700"
          />
          <h1 class="text-xl">
            {{ $t("designConfig.title") }}
          </h1>
        </div>
        <div class="grid grid-cols-1 gap-x-8 md:grid-cols-2">
          <div class="space-y-1">
            <p class="text-sm text-gray-900">
              {{ $t('designConfig.fields.primaryColor') }}
            </p>
            <p class="text-xs text-gray-500">
              {{ $t('designConfig.fields.primaryColorDescription') }}
            </p>
            <base-color-input
              v-model="webApp.designConfigAttributes.primaryColor"
              label="designConfig.fields.primaryColor"
              description="designConfig.fields.primaryColorDescription"
              name="designConfig.primaryColor"
              :placeholder="$t('designConfig.fields.primaryColor')"
            />
          </div>
          <div class="space-y-1">
            <p class="text-sm text-gray-900">
              {{ $t('designConfig.fields.secondaryColor') }}
            </p>
            <p class="text-xs text-gray-500">
              {{ $t('designConfig.fields.secondaryColorDescription') }}
            </p>
            <base-color-input
              v-model="webApp.designConfigAttributes.secondaryColor"
              label="designConfig.fields.secondaryColor"
              description="designConfig.fields.secondaryColorDescription"
              name="designConfig.secondaryColor"
              :placeholder="$t('designConfig.fields.secondaryColor')"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isSalesApp">
      <div class="mb-2 flex items-center space-x-3">
        <base-svg
          name="network"
          class="stroke-primary-700"
        />
        <h1 class="text-xl">
          {{ $t("envConfig.title") }}
        </h1>
      </div>
      <div class="grid grid-cols-1 gap-x-8 md:grid-cols-2">
        <div class="space-y-3">
          <base-input
            v-model="webApp.envConfigAttributes.mokUser"
            :placeholder="$t('envConfig.fields.mokUser')"
            label-i18n-path="envConfig.fields.mokUser"
            name="envConfig.mokUser"
          />
          <base-input
            v-model="webApp.envConfigAttributes.mokPassword"
            type="password"
            :placeholder="$t('envConfig.fields.mokPassword')"
            label-i18n-path="envConfig.fields.mokPassword"
            name="envConfig.mokPassword"
          />
          <base-input
            v-model="webApp.envConfigAttributes.adminUserEmail"
            :placeholder="$t('envConfig.fields.adminUserEmail')"
            label-i18n-path="envConfig.fields.adminUserEmail"
            name="envConfig.adminUserEmail"
          />
          <base-input
            v-model="webApp.envConfigAttributes.adminUserPassword"
            type="password"
            :placeholder="$t('envConfig.fields.adminUserPassword')"
            label-i18n-path="envConfig.fields.adminUserPassword"
            name="envConfig.adminUserPassword"
          />
        </div>
        <div class="space-y-3">
          <base-input
            v-model="webApp.envConfigAttributes.sidekiqAdminPassword"
            type="password"
            :placeholder="$t('envConfig.fields.sidekiqAdminPassword')"
            label-i18n-path="envConfig.fields.sidekiqAdminPassword"
            name="envConfig.sidekiqAdminPassword"
          />
        </div>
      </div>
    </div>
    <div>
      <base-button
        type="submit"
        data-testid="commit"
        theme="primary"
        :loading="isLoading"
        label-i18n-path="webApp.actions.create"
      />
    </div>
  </v-form>
</template>
