import { camelize } from 'humps';

import type { SectionFieldSchema } from '@/types/sections/section-field-schema';

function getSectionFieldComponentName(sectionFieldSchema: SectionFieldSchema) {
  const inputComponents = {
    imageSectionField: 'sections-image-section-field',
    textSectionField: 'sections-text-section-field',
    richTextSectionField: 'sections-rich-text-section-field',
    colorSectionField: 'sections-color-section-field',
    booleanSectionField: 'sections-boolean-section-field',
  };

  const sectionFieldType : keyof typeof inputComponents = camelize(sectionFieldSchema.type);

  return inputComponents[sectionFieldType];
}

export default getSectionFieldComponentName;
