<script setup lang="ts">
import { Switch } from '@headlessui/vue';
import { ref, watch, computed } from 'vue';

interface Props {
  modelValue?: boolean;
  disabled?: boolean;
  name: string;
  /** It must be a key in the i18n file. */
  uncheckedLabel: string,
  /** It must be a key in the i18n file. */
  checkedLabel: string,
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  disabled: false,
});

const checked = ref(props.modelValue);
const unchecked = computed(() => !checked.value);

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void}>();

watch(() => props.modelValue, (newValue: boolean) => {
  checked.value = newValue;
});
watch(checked, (newValue: boolean) => {
  if (!props.disabled) emit('update:modelValue', newValue);
});

const lookChecked = computed(() => (props.disabled ? props.modelValue : checked.value));

</script>
<template>
  <div class="flex items-center gap-4">
    <p :class="{'font-semibold text-warning-600': unchecked, 'text-gray-500': checked }">
      {{ $t(uncheckedLabel) }}
    </p>
    <Switch
      v-model="checked"
      data-testid="base-text-switch"
      :class="lookChecked ? 'bg-success-600' : 'bg-warning-600'"
      class="relative inline-flex h-5 w-8 items-center rounded-full"
      :disabled="disabled"
      :name="name"
    >
      <span
        v-if="lookChecked"
        data-testid="base-switch-check"
        class="inline-block h-4 w-4 translate-x-3.5 rounded-full bg-white transition"
      />
      <span
        v-else
        data-testid="base-switch-uncheck"
        class="inline-block h-4 w-4 translate-x-0.5 rounded-full bg-white transition"
      />
    </Switch>
    <p :class="{'font-semibold text-success-600': checked, 'text-gray-500': unchecked }">
      {{ $t(checkedLabel) }}
    </p>
  </div>
</template>
