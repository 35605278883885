<script setup lang="ts">
import { ref, computed } from 'vue';

import type { Category } from '@/types/category';
import type { Service } from '@/types/service';
import type { WebAppCategory } from '@/types/web-app-category';
import type { WebAppCountryService } from '@/types/web-app-country-service';

import CountryServicesSectionAddFamilies from './country-services-section-add-families.vue';
import CountryServicesSectionEditCategoryModal from './country-services-section-edit-category-modal.vue';
import CountryServicesSectionEditFamilyModal from './country-services-section-edit-family-modal.vue';
import CountryServicesSectionEditModal from './country-services-section-edit-modal.vue';
import CountryServicesSectionFamily from './country-services-section-family.vue';
import CountryServicesSectionSalesServiceFormModal from './country-services-section-sales-service-form-modal.vue';
import CountryServicesSectionShowModal from './country-services-section-show-modal.vue';

interface Props {
  webAppId: number;
  isSalesApp: boolean;
  webAppCategories: WebAppCategory[];
  webAppCountryServices: WebAppCountryService[];
  webAppSalesServices: Service[];
  families: Category[];
  categories: Category[];
  hasLoyalty: boolean;
}

interface Emits {
  (event: 'edit'): void;
  (event: 'update'): void;
  (event: 'update-category'): void;
  (event: 'update-sales-services'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const search = ref('');

function hasSearchPrefix(webAppCountryService: WebAppCountryService) {
  return webAppCountryService.name.toLowerCase().startsWith(search.value.toLowerCase());
}

const filteredWebAppCountryServices = computed(() => {
  let webAppCountryServices = props.webAppCountryServices;
  if (search.value.length > 0) {
    webAppCountryServices = props.webAppCountryServices.filter((webAppCountryService) =>
      (hasSearchPrefix(webAppCountryService)));
  }

  return webAppCountryServices;
});

const noSearchResult = computed(() => (filteredWebAppCountryServices.value.length === 0));

const selectedWebAppCountryService = ref<WebAppCountryService | Service>();
const selectedSalesService = ref<Service>();

const selectedFamily = ref<Category>();
const selectedFamilyWebAppCategory = computed(() => (
  props.webAppCategories.find((webAppCategory) => (webAppCategory.categoryId === selectedFamily.value?.id))
));

const selectedCategory = ref<Category>();
const selectedCategoryWebAppCategory = computed(() => (
  props.webAppCategories.find((webAppCategory) => (webAppCategory.categoryId === selectedCategory.value?.id))
));

const visibleShowModal = ref(false);
const visibleEditModal = ref(false);
const visibleEditFamilyModal = ref(false);
const visibleEditCategoryModal = ref(false);
const visibleSalesServiceFormModal = ref(false);

const isAddingNewFamilies = ref(false);

const webAppCountryServicesFamilyIds = computed(
  () => props.webAppCountryServices.map((webAppCountryService) => webAppCountryService.countryService.rootId),
);

const salesServicesFamilyIds = computed(
  () => props.webAppSalesServices.map(webAppSalesService => webAppSalesService.rootId),
);

const servicesFamilyIds = computed(
  () => webAppCountryServicesFamilyIds.value.concat(salesServicesFamilyIds.value),
);

const activeFamilies = computed(
  () => props.families.filter((family) => servicesFamilyIds.value.includes(family.id)),
);

const webAppCountryServicesCategoryIds = computed(
  () => props.webAppCountryServices.map((webAppCountryService) => webAppCountryService.countryService.categoryId),
);

const salesServicesCategoryIds = computed(
  () => props.webAppSalesServices.map(webAppSalesService => webAppSalesService.categoryId),
);

const servicesCategoryIds = computed(
  () => webAppCountryServicesCategoryIds.value.concat(salesServicesCategoryIds.value),
);

const activeCategories = computed(
  () => props.categories.filter((category) => servicesCategoryIds.value.includes(category.id)),
);

function showWebAppCountryService(webAppCountryService: WebAppCountryService | Service) {
  selectedWebAppCountryService.value = webAppCountryService;
  visibleShowModal.value = true;
}

function closeShowModal() {
  visibleShowModal.value = false;
  selectedWebAppCountryService.value = undefined;
}

function editWebAppCountryService(webAppCountryService: WebAppCountryService) {
  selectedWebAppCountryService.value = webAppCountryService;
  visibleEditModal.value = true;
}

function editFamily(family: Category) {
  selectedFamily.value = family;
  visibleEditFamilyModal.value = true;
}

function editCategory(category: Category) {
  selectedCategory.value = category;
  visibleEditCategoryModal.value = true;
}

function createSalesService(category: Category) {
  selectedCategory.value = category;
  visibleSalesServiceFormModal.value = true;
}

function editSalesService(service: Service) {
  selectedSalesService.value = service;
  visibleSalesServiceFormModal.value = true;
}

function closeEditModal() {
  visibleEditModal.value = false;
  selectedWebAppCountryService.value = undefined;
}

function closeEditFamilyModal() {
  visibleEditFamilyModal.value = false;
  selectedFamily.value = undefined;
}

function closeEditCategoryModal() {
  visibleEditCategoryModal.value = false;
  selectedCategory.value = undefined;
}

function closeSalesServiceFormModal() {
  visibleSalesServiceFormModal.value = false;
  selectedCategory.value = undefined;
  selectedSalesService.value = undefined;
}

function webAppCountryServicesForFamily(family: Category) {
  return props.webAppCountryServices.filter((webAppCountryService) =>
    (webAppCountryService.countryService.rootId === family.id));
}

function webAppSalesServicesForFamily(family: Category) {
  return props.webAppSalesServices.filter(webAppSalesService =>
    (webAppSalesService.rootId === family.id));
}

function activeCategoriesForFamily(family: Category) {
  return activeCategories.value.filter((category) =>
    (category.parentId === family.id));
}

function categoriesForFamily(family: Category) {
  return props.categories.filter((category) =>
    (category.parentId === family.id));
}

function onServiceSaved() {
  closeEditModal();
  emit('update');
}

function onSalesServiceSaved() {
  closeSalesServiceFormModal();
  emit('update-sales-services');
}

function onCategorySaved() {
  closeEditCategoryModal();
  emit('update-category');
}

function onFamilySaved() {
  closeEditFamilyModal();
  emit('update-category');
}

</script>

<template>
  <div
    v-if="isAddingNewFamilies"
  >
    <country-services-section-add-families
      :web-app-id="props.webAppId"
      :families="families"
      :active-families="activeFamilies"
      :categories="categories"
      @saved="isAddingNewFamilies = false"
      @cancel="isAddingNewFamilies = false"
    />
  </div>
  <div
    v-else-if="webAppCountryServices.length === 0"
    class="flex w-full flex-col items-center gap-6 bg-gray-50 py-12 md:py-16"
  >
    <div class="flex flex-col items-center gap-2">
      <base-svg
        name="search"
        class="h-8 w-8 fill-current text-gray-900"
      />
      <p
        class="text-xs"
      >
        {{ $t('countryServices.info.noServices') }}
      </p>
    </div>
    <base-button
      theme="primary"
      icon-file-name="add"
      label-i18n-path="countryServices.actions.addServices"
      @click="() => isAddingNewFamilies = true"
    />
  </div>
  <div
    v-else
  >
    <div class="flex grow justify-end text-sm md:text-base">
      <base-button
        theme="primary"
        icon-file-name="add"
        label-i18n-path="countryServices.actions.addFamilies"
        @click="() => isAddingNewFamilies = true"
      />
    </div>
    <div class="my-6 flex flex-col space-y-4 rounded-lg border border-gray-300 bg-white p-6 text-sm md:text-base">
      <div class="py-2">
        <base-search
          v-model="search"
          :placeholder="$t('actions.search')"
          name="search"
        />
      </div>
      <div class="h-96 overflow-auto">
        <div
          v-if="noSearchResult"
          class="text-gray-600"
        >
          {{ $t('countryServices.actions.notFound') }}
        </div>
        <country-services-section-family
          v-for="family in activeFamilies"
          :key="family.id"
          :family="family"
          :active-categories="activeCategoriesForFamily(family)"
          :categories="categoriesForFamily(family)"
          :web-app-categories="props.webAppCategories"
          :web-app-country-services="webAppCountryServicesForFamily(family)"
          :web-app-sales-services="webAppSalesServicesForFamily(family)"
          :web-app-id="props.webAppId"
          :is-sales-app="props.isSalesApp"
          :has-loyalty="hasLoyalty"
          @show="showWebAppCountryService"
          @edit="editWebAppCountryService"
          @edit-family="editFamily"
          @edit-category="editCategory"
          @create-sales-service="createSalesService"
          @edit-sales-service="editSalesService"
        />
      </div>
    </div>
  </div>
  <country-services-section-show-modal
    v-if="visibleShowModal && selectedWebAppCountryService"
    :web-app-country-service="selectedWebAppCountryService"
    :open="visibleShowModal"
    @close="closeShowModal"
  />
  <country-services-section-edit-modal
    v-else-if="visibleEditModal && selectedWebAppCountryService"
    :web-app-country-service="selectedWebAppCountryService as WebAppCountryService"
    :open="visibleEditModal"
    :has-loyalty="props.hasLoyalty"
    @save="onServiceSaved"
    @close="closeEditModal"
    @delete="onServiceSaved"
  />
  <country-services-section-edit-family-modal
    v-else-if="visibleEditFamilyModal && selectedFamily"
    :web-app-id="props.webAppId"
    :family="selectedFamily"
    :web-app-category="selectedFamilyWebAppCategory"
    :open="visibleEditFamilyModal"
    @save="onFamilySaved"
    @close="closeEditFamilyModal"
  />
  <country-services-section-edit-category-modal
    v-else-if="visibleEditCategoryModal && selectedCategory"
    :web-app-id="props.webAppId"
    :category="selectedCategory"
    :web-app-category="selectedCategoryWebAppCategory"
    :open="visibleEditCategoryModal"
    @save="onCategorySaved"
    @close="closeEditCategoryModal"
  />
  <country-services-section-sales-service-form-modal
    v-else-if="visibleSalesServiceFormModal && selectedCategory"
    :has-loyalty="props.hasLoyalty"
    :web-app-id="props.webAppId"
    :category="selectedCategory"
    :open="visibleSalesServiceFormModal"
    @save="onSalesServiceSaved"
    @close="closeSalesServiceFormModal"
  />
  <country-services-section-sales-service-form-modal
    v-else-if="visibleSalesServiceFormModal && selectedSalesService"
    :has-loyalty="props.hasLoyalty"
    :web-app-id="props.webAppId"
    :sales-service="selectedSalesService"
    :open="visibleSalesServiceFormModal"
    @save="onSalesServiceSaved"
    @delete="onSalesServiceSaved"
    @close="onSalesServiceSaved"
  />
</template>
