<script setup lang="ts">
import { ref, provide } from 'vue';

import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import type { Coupon } from '@/types/coupon';
import type { CuponstarCategory } from '@/types/cuponstar-category';
import type { EnvConfig } from '@/types/env-config';
import type { FunctionalityTabName } from '@/types/functionality-tab-name';
import type { WebApp } from '@/types/web-app';
import type { WebAppCoupon } from '@/types/web-app-coupon';
import type { WebAppTabs } from '@/types/web-app-tabs';

import BaseBadge from './base-badge.vue';
import ThePageHeader from './the-page-header.vue';
import webAppFunctionalitiesCoupons from './web-app-functionalities-coupons.vue';
import WebAppFunctionalitiesGeneral from './web-app-functionalities-general.vue';

export type Props = {
  webApp: WebApp;
  envConfig: EnvConfig;
  webAppCoupons: WebAppCoupon[];
  availableCoupons: Coupon[];
  cuponstarCategories: CuponstarCategory[];
  backPath: string,
  webAppTabs: WebAppTabs;
  canEnableCoupons: boolean;
}

const props = defineProps<Props>();

provide('webApp', props.webApp as WebApp);
provide('webAppCoupons', props.webAppCoupons as WebAppCoupon[]);
provide('availableCoupons', props.availableCoupons as Coupon[]);
provide('cuponstarCategories', props.cuponstarCategories as CuponstarCategory[]);

const selectedTab = ref('general');
const tabs = ref<FunctionalityTabName[]>([
  'general',
  'coupons',
]);

const isUpdateSuccess = ref(false);
const isUpdateError = ref(false);

const VISIBILITY_TIMEOUT = 3000;

const { isVisible: isSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateSuccess, timeout: VISIBILITY_TIMEOUT, reload: true },
);
const { isVisible: isErrorMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateError, timeout: VISIBILITY_TIMEOUT, reload: true },
);

function selectTab(tab: FunctionalityTabName) {
  selectedTab.value = tab;
}

function isSelectedTab(tab: FunctionalityTabName) {
  return selectedTab.value === tab;
}

</script>

<template>
  <the-web-app-layout
    :web-app="webApp"
    selected-tab="functionalities"
    :tabs="webAppTabs"
    :back-path="backPath"
  >
    <the-page-header
      title-i18n-path="functionalities.title"
      description-i18n-path="functionalities.description"
      emoji="⭐️"
    >
      <template #notice>
        <base-badge
          v-if="isErrorMessageVisible"
          :label="$t('common.saveError')"
          color="error"
          theme="dark"
        />
        <base-badge
          v-else-if="isSuccessMessageVisible"
          :label="$t('common.savedSuccessfully')"
          color="success"
          theme="dark"
        />
      </template>
    </the-page-header>
    <div class="mb-2">
      <div class="flex">
        <base-tab
          v-for="tab in tabs"
          :key="tab"
          :data-testid="`${tab}-tab`"
          class="-mb-px"
          :selected="isSelectedTab(tab)"
          :label-i18n-path="`functionalities.tabs.${tab}`"
          @select="() => selectTab(tab)"
        />
      </div>
      <hr class="-mx-4 border-gray-200 md:-mx-12">
    </div>
    <web-app-functionalities-general
      v-if="selectedTab === 'general'"
      :web-app="webApp"
      :env-config="envConfig"
      :can-enable-coupons="canEnableCoupons"
      @update-success="isUpdateSuccess = true"
      @update-error="isUpdateError = true"
    />
    <web-app-functionalities-coupons
      v-if="selectedTab === 'coupons'"
    />
  </the-web-app-layout>
</template>
