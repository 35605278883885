<script setup lang="ts">
import { ref } from 'vue';

interface Props {
  textI18nPath: string,
  disabled?: boolean,
}

withDefaults(defineProps<Props>(), {
  disabled: false,
});
const show = ref(false);
</script>

<template>
  <div
    class="relative"
    data-testid="mouseover-div"
    @mouseover="show = !disabled"
    @mouseout="show = false"
  >
    <slot />
    <div
      v-if="show"
      class="absolute left-1/2 top-1 z-10 min-w-max -translate-x-1/2 -translate-y-full rounded-lg bg-primary-700 px-4 py-2 text-xs text-white after:absolute after:left-1/2 after:top-[90%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-primary-700 after:content-['']"
      data-testid="tooltip"
    >
      {{ $t(textI18nPath) }}
    </div>
  </div>
</template>
