<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';

import useCouponsQuery from '@/composables/useCouponsQuery';
import type { Coupon } from '@/types/coupon';
import type { CuponstarCategory } from '@/types/cuponstar-category';
import type { WebApp } from '@/types/web-app';

const DEFAULT_MIN_DISCOUNT = 0;
const DEFAULT_MAX_DISCOUNT = 100;

interface Props {
  isOpen: boolean;
}

defineProps<Props>();

interface Emits {
  (e: 'updateFilters') : void;
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

const webApp = inject<WebApp>('webApp');
const availableCoupons = inject<Coupon[]>('availableCoupons');
const cuponstarCategories = inject<CuponstarCategory[]>('cuponstarCategories');

const webAppId = computed(() => webApp?.id || 0);

const {
  filters,
} = useCouponsQuery(
  webAppId.value,
  availableCoupons,
);

const discountFilter = ref([DEFAULT_MIN_DISCOUNT, DEFAULT_MAX_DISCOUNT]);

watch(discountFilter, (value) => {
  filters.numericDiscountGteq = value[0];
  filters.numericDiscountLteq = value[1];
});
</script>
<template>
  <base-modal
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <h3 class="text-2xl font-bold">
          {{ $t('functionalities.coupons.filters.title') }}
        </h3>
        <div class="mt-6 flex flex-col gap-4">
          <div>
            <p class="text-sm font-bold">
              {{ $t('functionalities.coupons.filters.byDiscounts') }}
            </p>
            <div class="mt-2 flex gap-2">
              <base-switch
                v-model="filters.numericDiscountNull"
                name="numericDiscountNull"
              />
              <p class="text-sm">
                {{ $t('functionalities.coupons.filters.withoutNumericDiscount') }}
              </p>
            </div>
            <base-slider
              v-if="!filters.numericDiscountNull"
              v-model="discountFilter"
              name="discount"
            />
          </div>
          <div>
            <p class="text-sm font-bold">
              {{ $t('functionalities.coupons.filters.byCategories') }}
            </p>
            <div class="mt-5 flex flex-col gap-5 md:grid md:grid-cols-5">
              <base-checkbox-input
                v-for="category in cuponstarCategories"
                :key="category.id"
                v-model="filters.cuponstarCategoriesIdIn"
                name="cuponstarCategoriesIdIn"
                :value="category.id"
                :label="category.name"
                class="text-xs"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <base-button
        theme="primary"
        label-i18n-path="actions.apply"
        @click="emit('updateFilters')"
      />
    </template>
  </base-modal>
</template>
