<script setup lang="ts">
import { reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import * as yup from 'yup';

import webAppCategoryApi from '@/api/webAppCategory';
import type { Category } from '@/types/category';
import type { WebAppCategory } from '@/types/web-app-category';

interface Props {
  webAppId: number;
  family: Category;
  webAppCategory?: WebAppCategory;
  open: boolean;
}

interface Emits {
  (event: 'close'): void;
  (event: 'save'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const isOpen = ref(props.open);

const { t } = useI18n({});

const webAppCategoryData = reactive<Partial<WebAppCategory>>({
  customName: props.webAppCategory?.customName || props.family.name,
  customDescription: props.webAppCategory?.customDescription || props.family.description,
  customImage: undefined,
  customIcon: undefined,
});

const webAppCategorySchema = yup.object().shape({
  customName: yup.string().label(t('webAppCategories.fields.customName')),
  customDescription: yup.string().label(t('webAppCategories.fields.customDescription')),
});

const {
  mutate,
  isError, isLoading,
} = useMutation(
  (values: WebAppCategory) => webAppCategoryApi.update(props.webAppId, props.family.id, values),
  { onSuccess: () => emit('save') },
);

function updateWebAppCategory(values: WebAppCategory) {
  mutate(values);
}

</script>
<template>
  <base-modal
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template #content="{ setInitialFocusRef }">
      <div class="mx-16">
        <h3 class="text-center text-3xl font-bold">
          {{ $t('webAppCategories.customization.families.title') }}
        </h3>
        <base-error
          v-if="isError"
          :message="$t('webAppCategories.errors.update')"
        />
        <h4 class="mt-6 text-center text-lg leading-6">
          {{ $t('webAppCategories.customization.families.subtitle') }}
        </h4>
        <v-form
          validate-on-mount
          :validation-schema="webAppCategorySchema"
          @submit="updateWebAppCategory"
        >
          <div
            class="mt-8 space-y-4"
          >
            <base-input
              :ref="setInitialFocusRef"
              v-model="webAppCategoryData.customName"
              label-i18n-path="webAppCategories.fields.customName"
              :placeholder="family.name"
              name="customName"
            />
            <base-input
              is="textarea"
              v-model="webAppCategoryData.customDescription"
              label-i18n-path="webAppCategories.fields.customDescription"
              :placeholder="family.description"
              name="customDescription"
            />
            <base-image-input
              v-model="webAppCategoryData.customImage"
              :label="$t('webAppCategories.fields.customImage')"
              name="customImage"
            />
            <base-svg-input
              v-model="webAppCategoryData.customIcon"
              :label="$t('webAppCategories.fields.customIcon')"
              name="customIcon"
              :hint="$t('webAppCategories.fields.customIconHint')"
            />
          </div>
          <div class="mt-8 flex justify-end space-x-6">
            <base-button
              type="button"
              theme="secondary"
              label-i18n-path="actions.cancel"
              @click="$emit('close')"
            />
            <base-button
              type="submit"
              theme="primary"
              :loading="isLoading"
              label-i18n-path="actions.save"
            />
          </div>
        </v-form>
      </div>
    </template>
  </base-modal>
</template>
