import { serialize } from 'object-to-formdata';

import type { Section } from '@/types/section';

import api from './index';

const BASE_PATH = '/api/internal/sections';

interface SectionUpdateProps {
  sectionId: number;
  sectionData: {
    type: string,
    sectionFields?: Record<string, unknown> };
}

export default {
  async update({ sectionId, sectionData }: SectionUpdateProps): Promise<{ section: Section }> {
    const data = serialize({
      section: { ...sectionData },
    });
    const path = `${BASE_PATH}/${sectionId}`;

    const response = await api({
      method: 'patch',
      url: path,
      data,
    });

    return response.data;
  },

  async destroy(sectionId: number): Promise<{ section: Section }> {
    const path = `${BASE_PATH}/${sectionId}`;

    const response = await api({
      method: 'delete',
      url: path,
    });

    return response.data;
  },
};
