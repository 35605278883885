<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogPanel,
} from '@headlessui/vue';

import BaseMokButton from '@/components/base-mok-button.vue';

export type Props = {
  isOpen: boolean;
  theme: 'success' | 'error' | 'warning';
  icon: string;
  title: string;
  description: string;
  cancelLabel?: string;
  confirmLabel?: string;
  loading?: boolean;
}

withDefaults(defineProps<Props>(), {
  cancelLabel: undefined,
  confirmLabel: undefined,
  loading: false,
});

const emits = defineEmits<{(e: 'close'): void, (e: 'confirm'): void}>();

function closeModal() {
  emits('close');
}

const iconBgStyles = {
  success: 'bg-success-100',
  error: 'bg-error-100',
  warning: 'bg-warning-100',
};

const iconColorStyles = {
  success: 'stroke-success-700',
  error: 'stroke-error-700',
  warning: 'stroke-warning-700',
};

</script>

<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-10"
      @close="closeModal"
    >
      <DialogOverlay
        class="fixed inset-0 bg-black opacity-60"
      />
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900 opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 flex h-screen items-center justify-center p-4 text-center">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="flex max-h-full max-w-max flex-col overflow-y-auto rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all md:max-w-xl"
          >
            <div class="flex items-start justify-end">
              <header class="flex flex-col items-center gap-y-4 text-center">
                <div
                  data-testid="icon-container"
                  class="mb-2 flex aspect-square h-14 items-center justify-center rounded-full"
                  :class="iconBgStyles[theme]"
                >
                  <base-svg
                    :class="iconColorStyles[theme]"
                    class="h-6 w-6"
                    :name="icon"
                  />
                </div>
                <h3 class="text-lg font-semibold text-gray-900">
                  {{ title }}
                </h3>

                <p class="text-sm font-normal text-gray-800">
                  {{ description }}
                </p>
              </header>
              <button
                data-testid="close-button"
                @click="closeModal"
              >
                <base-svg
                  name="close"
                  class="h-6 stroke-black"
                />
              </button>
            </div>
            <div
              data-testid="buttons-container"
              class="mt-8 flex flex-col-reverse gap-y-4 sm:flex-row"
              :class="{'grid-cols-2 gap-x-6 sm:grid': cancelLabel && confirmLabel}"
            >
              <base-mok-button
                v-if="cancelLabel"
                variant="secondary"
                size="large"
                full-width
                :label="cancelLabel"
                @click="$emit('close')"
              />
              <base-mok-button
                v-if="confirmLabel"
                variant="primary"
                size="large"
                full-width
                :label="confirmLabel"
                :loading="loading"
                @click="$emit('confirm')"
              />
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
