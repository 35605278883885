<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  mode: 'show' | 'add'
  willBeAdded?: boolean;
}

const props = withDefaults(defineProps<Props>(),
  {
    willBeAdded: undefined,
  },
);

interface Emits {
  (e: 'edit'): void;
  (e: 'delete'): void;
  (e: 'add'): void;
  (e: 'undoAdd'): void;
}

const showEditAndDeleteButtons = computed(() => props.mode === 'show');
const showAddButton = computed(() => props.mode === 'add' && !props.willBeAdded);
const showUndoAddButton = computed(() => props.mode === 'add' && props.willBeAdded);

defineEmits<Emits>();
</script>
<template>
  <template
    v-if="showEditAndDeleteButtons"
  >
    <base-button
      data-testid="edit-coupon-button"
      icon-file-name="pencil"
      size="md"
      @click="$emit('edit')"
    />
    <base-button
      data-testid="delete-coupon-button"
      icon-file-name="trash"
      size="md"
      @click="$emit('delete')"
    />
  </template>
  <base-button
    v-if="showAddButton"
    icon-file-name="add"
    label-i18n-path="functionalities.coupons.addCoupon"
    size="sm"
    theme="light-link"
    @click="$emit('add')"
  />
  <base-button
    v-if="showUndoAddButton"
    icon-file-name="arrow-uturn-left"
    label-i18n-path="actions.undo"
    size="sm"
    theme="primary-link"
    @click="$emit('undoAdd')"
  />
</template>
