<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { csrfToken } from '@rails/ujs';
import { inject } from 'vue';

import type { User } from '@/types/user';

const currentUser: User | undefined = inject('currentUser');

function signOut() {
  fetch('/users/sign_out', {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': csrfToken(),
    },
  }).then(() => window.location.replace('/'));
}
</script>

<template>
  <Menu
    v-if="currentUser"
    as="div"
    class="relative flex-col"
  >
    <MenuButton>
      <div class="flex items-center rounded-full bg-gray-200 px-2 py-1 lg:space-x-4 lg:px-4 lg:py-2">
        <img
          v-if="currentUser.imageUrl"
          class="aspect-square h-8 rounded-full"
          :src="currentUser.imageUrl"
        >
        <div
          v-else
          class="flex aspect-square h-8 items-center rounded-full bg-white p-2 text-gray-400"
        >
          <base-svg
            name="profile"
            class="h-4 fill-current"
          />
        </div>
        <div class="hidden items-start lg:flex lg:flex-col">
          <p class="text-sm  font-medium text-gray-900">
            {{ currentUser.name }}
          </p>

          <p class="text-xs font-medium text-gray-500">
            {{ $t(`user.attributes.roles.${currentUser.role}`) }}
          </p>
        </div>
        <div class="w-4">
          <base-svg
            name="arrow-down"
            class="w-4 stroke-current text-gray-500"
          />
        </div>
      </div>
    </MenuButton>
    <MenuItems class="absolute right-0 top-12 min-w-min rounded-md border border-gray-200 bg-white p-2 text-sm text-gray-500 shadow-md lg:top-14">
      <MenuItem>
        <p class="p-2 font-light text-gray-400">
          {{ currentUser.email }}
        </p>
      </MenuItem>
      <MenuItem class="flex w-full justify-end">
        <base-button
          variant="light-link"
          icon-file-name="close"
          right-icon
          size="xs"
          label-i18n-path="users.actions.logout"
          @click="signOut"
        />
      </MenuItem>
    </menuitems>
  </Menu>
</template>
