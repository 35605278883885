<script setup lang="ts">
import { computed } from 'vue';

import { useSectionPreviewImageSrc } from '@/composables/useSectionPreviewImageSrc';
import type { Section } from '@/types/section';
import type { SectionTypeName } from '@/types/sections/section-type-name';

import SectionFormPreviewItemButton from './section-form-preview-item-button.vue';

export type Props = {
  section: Section;
  selected?: boolean;
  selectedSectionType: SectionTypeName;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
  isDestroyable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  selected: false,
  canMoveUp: false,
  canMoveDown: false,
  isDestroyable: false,
});

defineEmits<{(e: 'loaded'): void, (e: 'up'): void, (e: 'down'): void, (e: 'destroy'): void}>();

const unselected = computed(() => !props.selected);
const updatedSectionType = computed(() => (props.selected ? props.selectedSectionType : props.section.type));
const { sectionPreviewImageSrc } = useSectionPreviewImageSrc(updatedSectionType);

const showDestroy = computed(() => (props.selected && props.isDestroyable));
const showEdit = computed(() => (props.section.path && unselected.value));
const editTheme = computed(() => (showDestroy.value ? 'secondary' : 'primary'));
const showMovementButtons = computed(() => (props.selected && (props.canMoveUp || props.canMoveDown)));

const selectedBorderStyle = {
  primary: 'border-2 border-solid border-primary-500',
  secondary: 'border-2 border-solid border-purple-400',
};

const movementButtonsStyles = {
  primary: 'bg-primary-700 disabled:bg-primary-300',
  secondary: 'bg-purple-400 disabled:bg-purple-300',
};

const borderStyle = computed(() => (
  props.selected ? selectedBorderStyle[editTheme.value] : 'border border-dashed border-gray-500'
));

const movementButtonsStyle = computed(() => movementButtonsStyles[editTheme.value]);

</script>

<template>
  <div
    class="relative mb-1 flex h-min grow overflow-hidden first:rounded-t-2xl last:rounded-b-2xl"
    :class="borderStyle"
  >
    <section-form-preview-item-button
      v-if="showEdit"
      :data-testid="`edit-section-${section.id}`"
      :href="section.path"
      icon="pencil-writing"
      theme="primary"
    />
    <section-form-preview-item-button
      v-if="showDestroy"
      :data-testid="`destroy-section-${section.id}`"
      icon="trash"
      theme="secondary"
      @click="$emit('destroy')"
    />
    <div
      v-if="showMovementButtons"
      data-testid="movement-buttons"
      class="absolute flex h-full w-full flex-col items-center justify-center"
    >
      <button
        data-testid="move-up-button"
        class="z-10 flex items-center justify-center rounded-t p-2 text-white"
        :class="movementButtonsStyle"
        :disabled="!canMoveUp"
        @click="$emit('up')"
      >
        <base-svg
          name="chevron-up"
          class="my-auto w-3 fill-current"
        />
      </button>
      <button
        data-testid="move-down-button"
        class="z-10 flex items-center justify-center rounded-b p-2 text-white"
        :class="movementButtonsStyle"
        :disabled="!canMoveDown"
        @click="$emit('down')"
      >
        <base-svg
          name="chevron-down"
          class="my-auto w-3 fill-current"
        />
      </button>
    </div>
    <img
      v-if="sectionPreviewImageSrc"
      :src="sectionPreviewImageSrc"
      class="w-full"
      :class="{'opacity-40': unselected || showMovementButtons}"
      @load="$emit('loaded')"
    >
  </div>
</template>
