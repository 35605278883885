<script setup lang="ts">

interface Props {
  name: string;
  labelI18nPath: string;
  modelValue?: string;
}

defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: string | undefined): void}>();

function handleChange(value: string | undefined) {
  emit('update:modelValue', value);
}

</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <h4 class="text-base font-semibold text-gray-900">
      {{ $t(labelI18nPath) }}
    </h4>
    <base-input
      :model-value="modelValue"
      :name="name"
      :placeholder="$t(labelI18nPath)"
      @update:model-value="handleChange"
    />
  </div>
</template>
