<script setup lang="ts">
import { computed, inject } from 'vue';

import type { DesignConfig } from '@/types/design-config';

interface Props {
  name: string;
  labelI18nPath: string;
  modelValue?: string;
}

defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: string | undefined): void}>();
const designConfig: DesignConfig | undefined = inject('designConfig');

const colors = computed(() => [
  designConfig?.primaryColor,
  designConfig?.secondaryColor,
].filter(Boolean));

function handleChange(value: string | undefined) {
  emit('update:modelValue', value);
}

</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <h4 class="text-base font-semibold text-gray-900">
      {{ $t(labelI18nPath) }}
    </h4>
    <base-rich-input
      :model-value="modelValue"
      :name="name"
      :colors="colors"
      @update:model-value="handleChange"
    />
  </div>
</template>
